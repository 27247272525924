import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";

import GridDX from "../layout/griddx";

import SelectListDX from "../controls/selectlistdx";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import YesNoSwitchDX from "../controls/yesnoswitchdx";

import { crsCountriesList, reasonList } from "../../shared/lookups";

const CRS1 = (props: any) => {
  return (
    <GridDX container spacing={2} sx={{ flex: 1 }}>
      <GridDX item xs={7}>
        <DiscTextFieldDX
          label="Country/jurisdiction of Tax residence"
          name="countryOfTaxId"
          value={props.data.countryOfTaxId?.value}
          isdiscrepant={props.discrepencies["countryOfTaxId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={7} justifyContent="space-between">
        <Typography style={{ fontSize: "12px", textAlign: "justify" }}>
          Is TIN (Tax Identification Number) Available?
        </Typography>

        <YesNoSwitchDX
          sx={{ alignSelf: "flex-end" }}
          name="isTINAvailable"
          readOnly={true}
          checked={props.data.isTINAvailable}
        />
      </GridDX>

      {props.data.isTINAvailable ? (
        <GridDX item xs={12} sm={7}>
          <DiscTextFieldDX
            name="tinNumber"
            label="Please Enter TIN ( Tax Identification Number )"
            value={props.data.tinNumber}
            isdiscrepant={props.discrepencies["tinNumber"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
      ) : (
        <GridDX item xs={10} sm={10}>
          {reasonList.find(
            (reason: any) => reason.id === Number(props.data.tinReasonId)
          ) && (
            <FormControlLabel
              control={<Radio color="primary" checked={true} disabled={true} />}
              style={{ textAlign: "justify", marginBottom: 15 }}
              label={
                reasonList
                  ? reasonList.find(
                      (reason: any) =>
                        reason.id === Number(props.data.tinReasonId)
                    )?.value
                  : ""
              }
            />
          )}
        </GridDX>
      )}

      {!props.data.isTINAvailable && props.data.tinReasonId === "2" && (
        <GridDX item xs={7}>
          <DiscTextFieldDX
            label="Justification required for Reason B selection"
            name="tinReasonDetail"
            value={props.data.tinReasonDetail}
            isdiscrepant={props.discrepencies["tinReasonDetail"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
      )}
    </GridDX>
  );
};

export default CRS1;
