import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import BoxDX from "../components/layout/boxdx";
import Basic from "../components/steps/basic";
import GridDX from "../components/layout/griddx";
import Personal from "../components/steps/personal";
import Bank from "../components/steps/bank";
import KYC from "../components/steps/kyc";
import AdditionalKYC from "../components/steps/additionalkyc";
import FATCA from "../components/steps/fatca";
import CRS1 from "../components/steps/crs1";
import DocUpload from "../components/steps/docupload";
import GridRowDividerDX from "../components/layout/gridrowdividerdx";
import SecondaryAppBarDX from "../components/appbars/secondaryappbar";
import Loading from "../components/loading";
import LoadingOverlay from "../components/loadingoverlay";
import NotificationBarDX from "../components/notificationbardx";

import { useErrorContext } from "../context/errorcontext";

import { onboardingData } from "../shared/classes";
import {
  ApplicationStatus,
  convertAPIDate,
  DATETIME_FORMAT,
  getFundName,
  getModeOfPayment,
  getReqeustStatus,
  translateAPIDataToFormData,
  VPSCategoryList,
  zeroPad,
} from "../shared/global";
import { useUserApplicationService } from "../shared/services/userapplicationservice";
import SalesRepresentative from "../components/steps/salesrep";
import CloseIcon from "@mui/icons-material/Close";
import DiscTextFieldDX from "../components/controls/discrepency/disctextfielddx";
import { Launch } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useInvestmentService } from "../shared/services/investmentservice";
import { Document, Page } from "react-pdf";
import Contribution from "../components/steps/contribution";
import ContributionDeclaration from "../components/steps/contdeclaration";
import ContributionNominee from "../components/steps/contributionnominee";
import RiskProfile from "../components/steps/riskprofile";

const ApplicationDetails = () => {
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <BoxDX
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        sx={{ p: 2 }}
        {...other}
      >
        {children}
      </BoxDX>
    );
  }

  const navigate = useNavigate();
  const { id } = useParams();
  const { setError, setInfo } = useErrorContext();
  const {
    approveUserApplication,
    getUserApplication,
    getUserApplicationDocuments,
    postUserApplication,
    rejectUserApplication,
    saveDataDiscrepancy,
    approveInitialInvestment,
    rejectInitialInvestment,
  } = useUserApplicationService();
  const { getInvestmentDetails, updateStatus } = useInvestmentService();

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [dataDiscrepency, setDataDiscrepency] = useState<any>({});
  const [dbUserApplication, setDBUserApplication] = useState<any | null>(null);
  const [userApplication, setUserApplication] = useState(onboardingData);
  const [applicationDocuments, setApplicationDocuments] = useState([]);
  const [investDetails, setInvestDetails] = useState<any | null>(null);
  const [showDoc, setShowDoc] = useState(false);
  const [document, setDocument] = useState("");

  useEffect(() => {
    if (!dbUserApplication) initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    getUserApplication(Number(id))
      .then(async (data) => {
        console.log("data", data);
        setDBUserApplication(data);

        const newUserApplication = await translateAPIDataToFormData(data);
        console.log({ newUserApplication });

        setUserApplication(newUserApplication);

        if (
          newUserApplication.applicationStatusId ===
          ApplicationStatus.InvestmentSubmitted
        ) {
          setValue(2);
        }

        if (data.initialInvestmentRequestID) {
          getInvestmentDetails(data.initialInvestmentRequestID).then(
            (investment) => {
              setInvestDetails(investment);
            }
          );
        }

        return getUserApplicationDocuments(newUserApplication.userApplicationId)
          .then((docs) => {
            const newDocumentList: any = [];

            docs.forEach((doc: any) => {
              let docImage = "";

              if (doc.document && doc.docType)
                docImage =
                  (doc.docType.includes("pdf")
                    ? "data:application/pdf;base64,"
                    : "data:image/jpeg;base64,") + doc.document;

              newDocumentList.push({
                ...doc,
                title: doc.shortName,
                document: docImage,
                modified: false,
              });
            });

            setApplicationDocuments(newDocumentList);
          })
          .catch((ex: any) => console.log(ex));
      })
      .finally(() => setIsLoading(false));
  };

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const toggleDiscrepency = (fieldName: string) => {
    // IMPORTANT to do it this way so that consecutive calls from within same function can be processed correctly
    setDataDiscrepency((crntValue: any) => {
      const newDiscrepency = { ...crntValue };

      if (newDiscrepency[fieldName]) delete newDiscrepency[fieldName];
      else newDiscrepency[fieldName] = true;

      return newDiscrepency;
    });
  };

  const postDiscrepency = async () => {
    const discrepantFields = Object.keys(dataDiscrepency).join(",");

    if (discrepantFields.length <= 0) {
      setError("Please mark at least one field as discrepant.");
    } else {
      let updatedApplication: any = { ...dbUserApplication };

      Object.keys(dataDiscrepency).forEach((field: string) => {
        updatedApplication = { ...updatedApplication, [field]: null };
      });

      console.log({ discrepantFields }, { updatedApplication });

      setIsSaving(true);

      saveDataDiscrepancy(updatedApplication, discrepantFields)
        .then(() => {
          setInfo("User informed about data discrepency");
          navigate(-1);
        })
        .catch((ex) => setError(ex))
        .finally(() => setIsSaving(false));
    }
  };

  console.log({ dataDiscrepency });

  const approveApplication = async () => {
    setIsSaving(true);

    if (
      userApplication.applicationStatusId ===
      ApplicationStatus.InvestmentSubmitted
    ) {
      approveInitialInvestment(userApplication.userApplicationId)
        .then(() => {
          setUserApplication({
            ...userApplication,
            applicationStatusId: ApplicationStatus.InvestmentApproved,
          });
          setDBUserApplication({
            ...dbUserApplication,
            applicationStatusId: ApplicationStatus.InvestmentApproved,
          });
          setInfo("Initial Investment approved successfully");
        })
        .catch((ex) => setError(ex))
        .finally(() => setIsSaving(false));
    } else {
      approveUserApplication(userApplication.userApplicationId)
        .then(() => {
          setUserApplication({ ...userApplication, applicationStatusId: 3 });
          setDBUserApplication({
            ...dbUserApplication,
            applicationStatusId: 3,
          });
          setInfo("Application approved successfully");
        })
        .catch((ex) => setError(ex))
        .finally(() => setIsSaving(false));
    }
  };

  const rejectApplication = async () => {
    setIsSaving(true);

    if (
      userApplication.applicationStatusId ===
      ApplicationStatus.InvestmentSubmitted
    ) {
      rejectInitialInvestment(userApplication.userApplicationId)
        .then(() => {
          setUserApplication({
            ...userApplication,
            applicationStatusId: ApplicationStatus.InvestmentRejected,
          });
          setDBUserApplication({
            ...dbUserApplication,
            applicationStatusId: ApplicationStatus.InvestmentRejected,
          });
          setInfo("Initial Investment rejected");
        })
        .catch((ex) => setError(ex))
        .finally(() => setIsSaving(false));
    } else {
      rejectUserApplication(userApplication.userApplicationId)
        .then(() => {
          setUserApplication({ ...userApplication, applicationStatusId: 4 });
          setDBUserApplication({
            ...dbUserApplication,
            applicationStatusId: 4,
          });
          setInfo("Application rejected successfully");
        })
        .catch((ex) => setError(ex))
        .finally(() => setIsSaving(false));
    }
  };

  const postData = async () => {
    // alert("Data posted to IT minds");
    setIsSaving(true);

    postUserApplication(userApplication.userApplicationId)
      .then((response) => {
        console.log("post response", response);
        setUserApplication({ ...userApplication, applicationStatusId: 5 });
        setDBUserApplication({ ...dbUserApplication, applicationStatusId: 5 });
        setInfo("Application approved successfully");
      })
      .catch((ex) => {
        console.log("error", ex);
        setError(ex);
      })
      .finally(() => setIsSaving(false));
  };

  const renderDocumentPopup = () => {
    const documentToView = document as string;

    if (documentToView?.split(";")[0].split(":")[1] === "application/pdf")
      return (
        <Document file={`${documentToView}`}>
          <Page pageNumber={1} renderTextLayer={false} />
        </Document>
      );
    else return <img src={documentToView} style={{ width: "100%" }} />;
  };

  return (
    <GridDX
      container
      style={{ height: "100vh", width: "100%", flexDirection: "column" }}
    >
      <NotificationBarDX />
      {isSaving && <LoadingOverlay />}
      <GridDX item xs={12}>
        <SecondaryAppBarDX
          postDiscrpency={
            userApplication.applicationStatusId === 1 ? postDiscrepency : null
          }
          approveApplication={
            userApplication.applicationStatusId === 1 ||
            userApplication.applicationStatusId ===
              ApplicationStatus.InvestmentSubmitted
              ? approveApplication
              : null
          }
          rejectApplication={
            userApplication.applicationStatusId === 1 ||
            userApplication.applicationStatusId ===
              ApplicationStatus.InvestmentSubmitted
              ? rejectApplication
              : null
          }
          postData={
            userApplication.applicationStatusId ===
            ApplicationStatus.InvestmentApproved
              ? postData
              : null
          }
        />
      </GridDX>
      <GridDX
        item
        xs={12}
        sx={{
          padding: 2,
          height: "calc(100vh - 65px)",
          flexDirection: "column",
        }}
      >
        <BoxDX sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Form Data" />
            <Tab label="Documents" />
            <Tab label="Initial Investment" />
          </Tabs>
        </BoxDX>
        <TabPanel value={value} index={0}>
          {isLoading ? (
            <Loading />
          ) : (
            <GridDX container rowSpacing={2}>
              <GridDX item xs={12}>
                <Typography variant="h4">Basic</Typography>
              </GridDX>
              <GridDX item xs={12} sx={{ pl: 1 }}>
                <Basic
                  data={userApplication}
                  discrepencies={dataDiscrepency}
                  togglediscrepency={
                    userApplication.applicationStatusId === 1
                      ? toggleDiscrepency
                      : null
                  }
                />
              </GridDX>
              <GridRowDividerDX />
              <GridDX item xs={12}>
                <Typography variant="h4">Personal</Typography>
              </GridDX>
              <GridDX item xs={12} sx={{ pl: 1 }}>
                <Personal
                  data={userApplication}
                  discrepencies={dataDiscrepency}
                  togglediscrepency={
                    userApplication.applicationStatusId === 1
                      ? toggleDiscrepency
                      : null
                  }
                />
              </GridDX>
              <GridRowDividerDX />
              <GridDX item xs={12}>
                <Typography variant="h4">Bank Details</Typography>
              </GridDX>
              <GridDX item xs={12} sx={{ pl: 1 }}>
                <Bank
                  data={userApplication}
                  discrepencies={dataDiscrepency}
                  togglediscrepency={
                    userApplication.applicationStatusId === 1
                      ? toggleDiscrepency
                      : null
                  }
                />
              </GridDX>
              <GridRowDividerDX />
              <GridDX item xs={12}>
                <Typography variant="h4">Know Your Customer</Typography>
              </GridDX>
              <GridDX item xs={12} sx={{ pl: 1 }}>
                <KYC
                  data={userApplication}
                  discrepencies={dataDiscrepency}
                  togglediscrepency={
                    userApplication.applicationStatusId === 1
                      ? toggleDiscrepency
                      : null
                  }
                />
              </GridDX>
              {userApplication.isPoliticallyExposedPerson && (
                <>
                  <GridRowDividerDX />
                  <GridDX item xs={12}>
                    <Typography variant="h4">
                      Politically Exposed Person
                    </Typography>
                  </GridDX>
                  <GridDX item xs={12} sx={{ pl: 1 }}>
                    <AdditionalKYC
                      data={userApplication}
                      discrepencies={dataDiscrepency}
                      togglediscrepency={
                        userApplication.applicationStatusId === 1
                          ? toggleDiscrepency
                          : null
                      }
                    />
                  </GridDX>
                </>
              )}

              {userApplication.isUSResident && (
                <>
                  <GridRowDividerDX />
                  <GridDX item xs={12}>
                    <Typography variant="h4">FATCA</Typography>
                  </GridDX>
                  <GridDX item xs={12} sx={{ pl: 1 }}>
                    <FATCA
                      data={userApplication}
                      discrepencies={dataDiscrepency}
                      togglediscrepency={
                        userApplication.applicationStatusId === 1
                          ? toggleDiscrepency
                          : null
                      }
                    />
                  </GridDX>
                </>
              )}
              {userApplication.isNonPakTaxResident && (
                <>
                  <GridRowDividerDX />
                  <GridDX item xs={12}>
                    <Typography variant="h4">CRS-1</Typography>
                  </GridDX>
                  <GridDX item xs={12} sx={{ pl: 1 }}>
                    <CRS1
                      data={userApplication}
                      discrepencies={dataDiscrepency}
                      togglediscrepency={
                        userApplication.applicationStatusId === 1
                          ? toggleDiscrepency
                          : null
                      }
                    />
                  </GridDX>
                </>
              )}
              <GridDX item xs={12}>
                <Typography variant="h4">Sales Representative</Typography>
              </GridDX>
              <GridDX item xs={12} sx={{ pl: 1 }}>
                <SalesRepresentative
                  data={userApplication}
                  discrepencies={dataDiscrepency}
                  togglediscrepency={
                    userApplication.applicationStatusId === 1
                      ? toggleDiscrepency
                      : null
                  }
                />
              </GridDX>

              {userApplication.accountCategoryId &&
                VPSCategoryList.includes(
                  userApplication.accountCategoryId?.id
                ) && (
                  <>
                    <GridRowDividerDX />
                    <GridDX item xs={12}>
                      <Typography variant="h4">Contribution</Typography>
                    </GridDX>
                    <GridDX item xs={12} sx={{ pl: 1 }}>
                      <Contribution
                        data={userApplication}
                        discrepencies={dataDiscrepency}
                        togglediscrepency={
                          userApplication.applicationStatusId === 1
                            ? toggleDiscrepency
                            : null
                        }
                      />
                    </GridDX>

                    <GridRowDividerDX />
                    <GridDX item xs={12}>
                      <Typography variant="h4">
                        Contribution Declaration
                      </Typography>
                    </GridDX>
                    <GridDX item xs={12} sx={{ pl: 1 }}>
                      <ContributionDeclaration
                        data={userApplication}
                        discrepencies={dataDiscrepency}
                        togglediscrepency={
                          userApplication.applicationStatusId === 1
                            ? toggleDiscrepency
                            : null
                        }
                      />
                    </GridDX>

                    <GridRowDividerDX />
                    <GridDX item xs={12}>
                      <Typography variant="h4">Contribution Nominee</Typography>
                    </GridDX>
                    <GridDX item xs={12} sx={{ pl: 1 }}>
                      <ContributionNominee
                        data={userApplication}
                        discrepencies={dataDiscrepency}
                        togglediscrepency={
                          userApplication.applicationStatusId === 1
                            ? toggleDiscrepency
                            : null
                        }
                      />
                    </GridDX>

                    <GridRowDividerDX />
                    <GridDX item xs={12}>
                      <Typography variant="h4">Risk Profile</Typography>
                    </GridDX>
                    <GridDX item xs={12} sx={{ pl: 1 }}>
                      <RiskProfile
                        data={userApplication}
                        discrepencies={dataDiscrepency}
                        togglediscrepency={
                          userApplication.applicationStatusId === 1
                            ? toggleDiscrepency
                            : null
                        }
                      />
                    </GridDX>
                  </>
                )}
            </GridDX>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isLoading ? (
            <Loading />
          ) : (
            <DocUpload
              data={userApplication}
              documentList={applicationDocuments}
              discrepencies={dataDiscrepency}
              togglediscrepency={
                userApplication.applicationStatusId === 1
                  ? toggleDiscrepency
                  : null
              }
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {isLoading ? (
            <Loading />
          ) : (
            investDetails != null && (
              <GridDX container rowSpacing={2}>
                <GridDX item xs={12}>
                  <Dialog
                    fullScreen
                    open={showDoc}
                    onClose={() => {
                      setShowDoc(false);
                      setDocument("");
                    }}
                  >
                    <DialogContent
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <GridDX
                        container
                        sx={{ height: "100%" }}
                        alignItems="flex-start"
                      >
                        <GridDX item xs={12}>
                          <IconButton
                            size="large"
                            onClick={() => {
                              setShowDoc(false);
                              setDocument("");
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </GridDX>
                        <GridDX
                          item
                          xs={12}
                          sx={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {showDoc && document && renderDocumentPopup()}
                        </GridDX>
                      </GridDX>
                    </DialogContent>
                  </Dialog>
                  <GridDX container sx={{ px: 2 }} rowSpacing={2}>
                    <GridDX item xs={12}>
                      <DiscTextFieldDX
                        label="Request Date"
                        name="requestDate"
                        value={convertAPIDate(
                          investDetails.requestDate
                        )?.format(DATETIME_FORMAT)}
                      />
                    </GridDX>

                    <GridDX item xs={12}>
                      <DiscTextFieldDX
                        label="Fund Name"
                        name="fundId"
                        value={getFundName(investDetails.fundId)}
                      />
                    </GridDX>

                    <GridDX item xs={12}>
                      <DiscTextFieldDX
                        label="Investment Amount"
                        name="investmentAmount"
                        value={Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(investDetails.investmentAmount)}
                      />
                    </GridDX>
                    <GridDX item xs={12}>
                      <DiscTextFieldDX
                        label="Payment Mode"
                        name="paymentMode"
                        value={getModeOfPayment(investDetails.paymentMode)}
                      />
                    </GridDX>
                    <GridDX item xs={12}>
                      <DiscTextFieldDX
                        label="Online Payment Reference"
                        name="onlinePaymentReference"
                        value={investDetails.onlinePaymentReference}
                      />
                    </GridDX>
                    <GridDX item xs={12} sx={{ flexDirection: "column" }}>
                      <DiscTextFieldDX
                        label="Status"
                        name="requestStatus"
                        value={
                          getReqeustStatus(investDetails.requestStatus)?.value
                        }
                      />
                    </GridDX>
                    {investDetails.proofOfPayment && (
                      <GridDX
                        item
                        xs={12}
                        sx={{
                          width: "100%",
                          padding: 2,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          overflowWrap: "anywhere",
                          backgroundColor: "#efefef",
                          mx: 1,
                        }}
                      >
                        {investDetails.proofOfPayment
                          ?.split(";")[0]
                          .split(":")[1] !== "application/pdf" ? (
                          <img
                            src={investDetails.proofOfPayment}
                            height={100}
                          />
                        ) : (
                          <PictureAsPdfIcon />
                        )}
                        <Launch
                          style={{
                            cursor: "pointer",
                            alignSelf: "flex-end",
                            marginLeft: "8px",
                          }}
                          onClick={() => {
                            setDocument(investDetails.proofOfPayment);
                            setShowDoc(true);
                          }}
                        />
                      </GridDX>
                    )}
                  </GridDX>
                </GridDX>
              </GridDX>
            )
          )}
        </TabPanel>
      </GridDX>
    </GridDX>
  );
};

export default ApplicationDetails;
