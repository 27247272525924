import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

const DiscDocument = (props: any) => {
  return (
    props.togglediscrepency && (
      <IconButton
        onClick={() =>
          props.togglediscrepency ? props.togglediscrepency(props.name) : null
        }
      >
        {props.isdiscrepant ? (
          <RefreshIcon fontSize="large" color="success" />
        ) : (
          <CloseIcon fontSize="large" color="error" />
        )}
      </IconButton>
    )
  );
};

export default DiscDocument;
