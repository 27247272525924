import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GridDX from "../components/layout/griddx";
import { useErrorContext } from "../context/errorcontext";
import {
  DATETIME_FORMAT,
  getFundName,
  RequestStatus,
  zeroPad,
} from "../shared/global";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DiscTextFieldDX from "../components/controls/discrepency/disctextfielddx";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { convertAPIDate } from "../shared/global";
import { getReqeustStatus } from "../shared/global";
import Loading from "../components/loading";
import SecondaryAppBarDX from "../components/appbars/secondaryappbar";
import NotificationBarDX from "../components/notificationbardx";
import LoadingOverlay from "../components/loadingoverlay";
import { useRedemptionService } from "../shared/services/redemptionservice";
import { getWithdrawalTypeById } from "../shared/lookups";

const RedemptionDetails = () => {
  const { id } = useParams();
  const { getRedemptionDetails, updateStatus } = useRedemptionService();

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<any | null>(null);
  const { setError, setInfo } = useErrorContext();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    getRedemptionDetails(Number(id))
      .then(async (data) => {
        console.log("data", data);
        setDetails(data);
      })
      .catch((error) => console.log("ERROR!", error))
      .finally(() => setIsLoading(false));
  };

  const updateRedemptionReqStatus = async (statusId: number) => {
    setIsSaving(true);
    updateStatus({ ...details, requestStatus: statusId })
      .then(async (response) => {
        console.log("response", response);
        setInfo("Status updated");
        await initialize();
      })
      .catch(async (error) => {
        console.log("error", error);
        setError(error);
        await initialize();
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isLoading ? (
        <Loading />
      ) : (
        <GridDX container spacing={2}>
          <NotificationBarDX />
          {isSaving && <LoadingOverlay />}
          <GridDX item xs={12}>
            <SecondaryAppBarDX
              postDiscrpency={null}
              approveApplication={
                details.requestStatus === RequestStatus.Pending
                  ? () => updateRedemptionReqStatus(RequestStatus.Approved)
                  : null
              }
              rejectApplication={
                details.requestStatus === RequestStatus.Pending
                  ? () => updateRedemptionReqStatus(RequestStatus.Rejected)
                  : null
              }
              postData={
                details.requestStatus === RequestStatus.Approved
                  ? () => updateRedemptionReqStatus(RequestStatus.Posted)
                  : null
              }
            />
          </GridDX>

          <GridDX container item sx={{ pl: 2, pr: 2 }}>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Request Date"
                name="requestDate"
                value={convertAPIDate(details.requestDate)?.format(
                  DATETIME_FORMAT
                )}
              />
            </GridDX>
            <GridDX item xs={6}>
              <DiscTextFieldDX
                label="User Name"
                name="userName"
                value={details.userName}
              />
            </GridDX>
            <GridDX item xs={3}>
              <DiscTextFieldDX label="CNIC" name="cnic" value={details.cnic} />
            </GridDX>
            <GridDX item xs={3}>
              <DiscTextFieldDX
                label="Folio Number"
                name="folioNumber"
                value={zeroPad(details.folioNumber, 7)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Fund Name"
                name="fundId"
                value={getFundName(details.fundId)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Redemption Amount"
                name="redemptionAmount"
                value={Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(details.redemptionAmount)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Back End Load"
                name="backEndLoad"
                value={details.backEndLoad}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Nav Applied"
                name="navApplied"
                value={details.navApplied}
              />
            </GridDX>
            <GridDX item xs={12} sx={{ flexDirection: "column" }}>
              <DiscTextFieldDX
                label="Status"
                name="requestStatus"
                value={getReqeustStatus(details.requestStatus)?.value}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Bank ID"
                name="bankID"
                value={details.bankID}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Bank Account Number"
                name="bankAccountNo"
                value={details.bankAccountNo}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Withdrawal Type"
                name="withdrawalType"
                value={getWithdrawalTypeById(details.withdrawalType)}
              />
            </GridDX>
          </GridDX>
        </GridDX>
      )}
    </LocalizationProvider>
  );
};

export default RedemptionDetails;
