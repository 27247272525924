import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import moment, { Moment } from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import SelectListDX from "../controls/selectlistdx";
import MaskInput from "../controls/maskinput/maskinput";

import {
  retirementAgeList,
  getPlanListByAccountCategory,
} from "../../shared/lookups";

import { useAuthContext } from "../../context/authcontext";
import { useConfigContext } from "../../context/configcontext";
import GridDX from "../layout/griddx";
import { VPSCategoryList } from "../../shared/global";

const Basic = (props: any) => {
  const { DATE_FORMAT } = useConfigContext();
  const ACT_CATEGORY_GENERAL = 1;

  const theme = useTheme();
  const history = useNavigate();
  const useMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const [mobileNumber, setMobileNumber] = useState("");

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GridDX container spacing={2}>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Account Category"
            name="accountCategoryId"
            value={props.data.accountCategoryId?.value}
            isdiscrepant={props.discrepencies["accountCategoryId"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="ID Card Number"
            name="cnic"
            value={props.data.cnic}
            isdiscrepant={props.discrepencies["cnic"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Name as per ID Card"
            name="name"
            value={props.data.name}
            isdiscrepant={props.discrepencies["name"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Father / Husband Name"
            name="fatherHusbandName"
            value={props.data.fatherHusbandName}
            isdiscrepant={props.discrepencies["fatherHusbandName"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Gender"
            name="gender"
            value={props.data.genderId?.value}
            isdiscrepant={props.discrepencies["genderId"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>

        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="ID Card Date of Birth"
            name="dateOfBirth"
            value={props.data.dateOfBirth}
            isdiscrepant={props.discrepencies["dateOfBirth"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="ID Card Issue Date"
            name="cnicIssueDate"
            value={props.data.cnicIssueDate}
            isdiscrepant={props.discrepencies["cnicIssueDate"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6} sx={{ flexDirection: "column" }}>
          <DiscTextFieldDX
            label="ID Card Exipry Date"
            name="cnicExpiryDate"
            value={props.data.cnicExpiryDate}
            isdiscrepant={props.discrepencies["cnicExpiryDate"]}
            togglediscrepency={props.togglediscrepency}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isCNICLifeTime"
                checked={props.data.isCNICLifeTime}
                disabled
                color="primary"
              />
            }
            label="Lifetime Expiry Date"
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Mother's Maiden Name"
            name="motherMaidenName"
            value={props.data.motherMaidenName}
            isdiscrepant={props.discrepencies["motherMaidenName"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label={`Ownership of Mobile No. ${mobileNumber}`}
            name="contactOwnershipId"
            value={props.data.contactOwnershipId?.value}
            isdiscrepant={props.discrepencies["contactOwnershipId"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>

        {props.data.accountCategoryId &&
          VPSCategoryList.includes(props.data.accountCategoryId?.id) && (
            <>
              <GridDX item xs={12} sm={6}>
                <DiscTextFieldDX
                  label="Retirement Age"
                  name="expectedRetirementAge"
                  value={props.data.expectedRetirementAge}
                  isdiscrepant={props.discrepencies["expectedRetirementAge"]}
                  togglediscrepency={props.togglediscrepency}
                />
              </GridDX>
              <GridDX item xs={12} sm={6} sx={{ flexDirection: "column" }}>
                <DiscTextFieldDX
                  label="VPS Fund Selection"
                  name="vpsFundId"
                  value={props.data.vpsFundId?.value}
                  isdiscrepant={props.discrepencies["vpsFundId"]}
                  togglediscrepency={props.togglediscrepency}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  {props.data.vpsFundId !== null && (
                    <>
                      <DiscTextFieldDX
                        key={"key_per_" + 1}
                        value={props.data.equitySubFund + "%"}
                        name="equitySubFund"
                        label={"Equity"}
                        style={{ maxWidth: 115 }}
                        isdiscrepant={props.discrepencies["equitySubFund"]}
                        togglediscrepency={
                          props.data.vpsFundId.value === "Customized"
                            ? props.togglediscrepency
                            : undefined
                        }
                      />
                      <DiscTextFieldDX
                        key={"key_per_" + 2}
                        value={props.data.debtSubFund + "%"}
                        name="debtSubFund"
                        label={"Debt"}
                        style={{ maxWidth: 115 }}
                        isdiscrepant={props.discrepencies["debtSubFund"]}
                        togglediscrepency={
                          props.data.vpsFundId.value === "Customized"
                            ? props.togglediscrepency
                            : undefined
                        }
                      />
                      <DiscTextFieldDX
                        key={"key_per_" + 3}
                        value={props.data.moneyMarketSubFund + "%"}
                        name="moneyMarketSubFund"
                        label={"Money Market"}
                        style={{ maxWidth: 115 }}
                        isdiscrepant={props.discrepencies["moneyMarketSubFund"]}
                        togglediscrepency={
                          props.data.vpsFundId.value === "Customized"
                            ? props.togglediscrepency
                            : undefined
                        }
                      />
                    </>
                  )}
                </div>
              </GridDX>
            </>
          )}
      </GridDX>
    </LocalizationProvider>
  );
};

export default Basic;
