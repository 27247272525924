import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import BoxDX from "../components/layout/boxdx";
import GridDX from "../components/layout/griddx";

import { useErrorContext } from "../context/errorcontext";
import {
  DATETIME_FORMAT,
  getFundName,
  RequestStatus,
  zeroPad,
} from "../shared/global";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DiscTextFieldDX from "../components/controls/discrepency/disctextfielddx";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { convertAPIDate } from "../shared/global";
import { getModeOfPayment } from "../shared/global";
import { getReqeustStatus } from "../shared/global";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Loading from "../components/loading";
import ButtonDX from "../components/controls/buttondx";
import SecondaryAppBarDX from "../components/appbars/secondaryappbar";
import NotificationBarDX from "../components/notificationbardx";
import LoadingOverlay from "../components/loadingoverlay";
import { Launch } from "@mui/icons-material";
import { Document, Page } from "react-pdf";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Theme,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useInvestmentService } from "../shared/services/investmentservice";

const InvestmentDetails = () => {
  const { id } = useParams();
  const { setError, setInfo } = useErrorContext();
  const { getInvestmentDetails, updateStatus } = useInvestmentService();

  const [isLoading, setIsLoading] = useState(true);
  const [investDetails, setInvestDetails] = useState<any | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [document, setDocument] = useState("");

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    getInvestmentDetails(Number(id))
      .then(async (data) => {
        setInvestDetails(data);
      })
      .catch((error) => console.log("ERROR!", error))
      .finally(() => setIsLoading(false));
  };

  const updateInvestmentStatus = async (statusId: number) => {
    setIsSaving(true);
    updateStatus({ ...investDetails, requestStatus: statusId })
      .then(async (response) => {
        console.log("response", response);
        setInfo("Status updated");
        await initialize();
      })
      .catch(async (error) => {
        console.log("error", error);
        setError(error);
        await initialize();
      })
      .finally(() => setIsSaving(false));
  };

  const renderDocumentPopup = () => {
    const documentToView = document as string;

    if (documentToView?.split(";")[0].split(":")[1] === "application/pdf")
      return (
        <Document file={`${documentToView}`}>
          <Page pageNumber={1} renderTextLayer={false} />
        </Document>
      );
    else return <img src={documentToView} style={{ width: "100%" }} />;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isLoading ? (
        <Loading />
      ) : (
        <GridDX container spacing={2}>
          <NotificationBarDX />
          {isSaving && <LoadingOverlay />}
          <GridDX item xs={12}>
            <SecondaryAppBarDX
              postDiscrpency={null}
              approveApplication={
                investDetails.requestStatus === RequestStatus.Pending
                  ? () => updateInvestmentStatus(RequestStatus.Approved)
                  : null
              }
              rejectApplication={
                investDetails.requestStatus === RequestStatus.Pending
                  ? () => updateInvestmentStatus(RequestStatus.Rejected)
                  : null
              }
              postData={
                investDetails.requestStatus === RequestStatus.Approved
                  ? () => updateInvestmentStatus(RequestStatus.Posted)
                  : null
              }
            />
          </GridDX>
          <GridDX item xs={12}>
            <Dialog
              fullScreen
              open={showDoc}
              onClose={() => {
                setShowDoc(false);
                setDocument("");
              }}
            >
              <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <GridDX
                  container
                  sx={{ height: "100%" }}
                  alignItems="flex-start"
                >
                  <GridDX item xs={12}>
                    <IconButton
                      size="large"
                      onClick={() => {
                        setShowDoc(false);
                        setDocument("");
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </GridDX>
                  <GridDX
                    item
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {showDoc && document && renderDocumentPopup()}
                  </GridDX>
                </GridDX>
              </DialogContent>
            </Dialog>
            <GridDX container sx={{ px: 2 }}>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Request Date"
                  name="requestDate"
                  value={convertAPIDate(investDetails.requestDate)?.format(
                    DATETIME_FORMAT
                  )}
                />
              </GridDX>
              <GridDX item xs={6}>
                <DiscTextFieldDX
                  label="User Name"
                  name="userName"
                  value={investDetails.userName}
                />
              </GridDX>
              <GridDX item xs={3}>
                <DiscTextFieldDX
                  label="CNIC"
                  name="cnic"
                  value={investDetails.cnic}
                />
              </GridDX>
              <GridDX item xs={3}>
                <DiscTextFieldDX
                  label="Folio Number"
                  name="folioNumber"
                  value={zeroPad(investDetails.folioNumber, 7)}
                />
              </GridDX>

              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Fund Name"
                  name="fundId"
                  value={getFundName(investDetails.fundId)}
                />
              </GridDX>

              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Investment Amount"
                  name="investmentAmount"
                  value={Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(investDetails.investmentAmount)}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Payment Mode"
                  name="paymentMode"
                  value={getModeOfPayment(investDetails.paymentMode)}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Online Payment Reference"
                  name="onlinePaymentReference"
                  value={investDetails.onlinePaymentReference}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Branch ID"
                  name="branchId"
                  value={investDetails.branchId}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Bank ID"
                  name="itmindsBankID"
                  value={investDetails.itMindsBankID}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Bank Name"
                  name="bankName"
                  value={investDetails.bankName}
                />
              </GridDX>
              <GridDX item xs={12}>
                <DiscTextFieldDX
                  label="Account Number"
                  name="accountNumber"
                  value={investDetails.accountNumber}
                />
              </GridDX>
              <GridDX item xs={12} sx={{ flexDirection: "column" }}>
                <DiscTextFieldDX
                  label="Status"
                  name="requestStatus"
                  value={getReqeustStatus(investDetails.requestStatus)?.value}
                />
              </GridDX>
              {investDetails.proofOfPayment && (
                <GridDX
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    padding: 2,
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderRadius: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    overflowWrap: "anywhere",
                    backgroundColor: "#efefef",
                    mx: 1,
                  }}
                >
                  {investDetails.proofOfPayment?.split(";")[0].split(":")[1] !==
                  "application/pdf" ? (
                    <img src={investDetails.proofOfPayment} height={100} />
                  ) : (
                    <PictureAsPdfIcon />
                  )}
                  <Launch
                    style={{
                      cursor: "pointer",
                      alignSelf: "flex-end",
                      marginLeft: "8px",
                    }}
                    onClick={() => {
                      setDocument(investDetails.proofOfPayment);
                      setShowDoc(true);
                    }}
                  />
                </GridDX>
              )}
            </GridDX>
          </GridDX>
        </GridDX>
      )}
    </LocalizationProvider>
  );
};

export default InvestmentDetails;
