import useSecureAPI from "../hooks/usesecureapi";

export const useInvestmentService = () => {
  const secureAPI = useSecureAPI();

  const getAllInvestments = async () => {
    const response = await secureAPI.get("InvestmentRequest");
    return response.data;
  };

  const getInvestmentDetails = async (investmentRequestId: number) => {
    const response = await secureAPI.get(
      "InvestmentRequest/" + investmentRequestId
    );
    return response.data;
  };

  const updateStatus = async (updateStatusRequest: any) => {
    const response = await secureAPI.put(
      "InvestmentRequest/updatestatus",
      updateStatusRequest
    );
    return response.data;
  };

  return {
    getAllInvestments,
    getInvestmentDetails,
    updateStatus,
  };
};
