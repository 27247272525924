import { Link, Outlet } from "react-router-dom";

import GridDX from "../components/layout/griddx";
import AppBarDX from "../components/appbars/dashboardappbardx";
import NotificationBarDX from "../components/notificationbardx";
import {
  ContentPaste,
  Money,
  KeyboardReturn,
  MoveUp,
} from "@mui/icons-material";

const DashboardTemplate = () => {
  const menu = [
    {
      text: "Applications",
      icon: <ContentPaste style={{ color: "#77BBBB" }} />,
      linkTo: "/dashboard",
    },
    {
      text: "Investments",
      icon: <Money style={{ color: "#77BBBB" }} />,
      linkTo: "/investments",
    },
    {
      text: "Redemption",
      icon: <KeyboardReturn style={{ color: "#77BBBB" }} />,
      linkTo: "/redemptions",
    },
    {
      text: "Funds Transfer",
      icon: <MoveUp style={{ color: "#77BBBB" }} />,
      linkTo: "/ftrequests",
    },
  ];

  return (
    <GridDX container style={{ height: "100vh", width: "100%" }}>
      <GridDX item xs={12}>
        <NotificationBarDX />
        <AppBarDX />
      </GridDX>
      <GridDX
        container
        xs={2}
        sx={{
          backgroundColor: "#007A48",
          color: "white",
          height: "100vh",
          width: "120px",
        }}
      >
        <GridDX
          item
          xs={12}
          sx={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {menu.map((item) => {
            return (
              <>
                <Link
                  to={item.linkTo}
                  style={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {item.icon}
                  {item.text}
                </Link>
                <br />
              </>
            );
          })}
        </GridDX>
      </GridDX>
      <GridDX
        item
        xs={10}
        style={{
          padding: 16,
          height: "calc(100vh - 65px)",
        }}
      >
        <Outlet />
      </GridDX>
    </GridDX>
  );
};

export default DashboardTemplate;
