import React, { useEffect, useState } from "react";
import { Typography, IconButton } from "@mui/material";

import DispTextFieldDX from "../controls/discrepency/disctextfielddx";

import GridDX from "../layout/griddx";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";

const SalesRepresentative = (props: any) => {
  return (
    <GridDX container spacing={2} sx={{ flex: 1 }}>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Name/Code"
          name="namecode"
          value={props.data.salesRepresentativeNameCode}
          isdiscrepant={props.discrepencies["salesRepresentativeNameCode"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Mobile Number"
          name="mobilenumber"
          value={props.data.salesRepresentativeMobileNumber}
          isdiscrepant={props.discrepencies["salesRepresentativeMobileNumber"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
    </GridDX>
  );
};

export default SalesRepresentative;
