import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";

import { useErrorContext } from "../context/errorcontext";

import GridDX from "../components/layout/griddx";
import ButtonDX from "../components/controls/buttondx";

import {
  DATETIME_FORMAT,
  getFundName,
  getReqeustStatus,
  zeroPad,
} from "../shared/global";
import moment from "moment";
import { useFundTransferService } from "../shared/services/fundtransferservice";

const FundTransferRequests = () => {
  const columns: GridColumns = [
    {
      field: "requestDate",
      headerName: "Request Date",
      headerAlign: "center",
      width: 180,
      valueFormatter: ({ value }) => moment(value).format(DATETIME_FORMAT),
    },
    {
      field: "userName",
      headerName: "User Name",
      headerAlign: "center",
      width: 180,
    },
    {
      field: "cnic",
      headerName: "CNIC",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "folioNumber",
      headerName: "Folio",
      headerAlign: "center",
      align: "center",
      width: 100,
      valueFormatter: ({ value }) => zeroPad(value, 7),
    },
    {
      field: "fromFundId",
      headerName: "From Fund Name",
      headerAlign: "center",
      width: 240,
      valueFormatter: ({ value }) => getFundName(value),
    },
    {
      field: "toFundId",
      headerName: "To Fund Name",
      headerAlign: "center",
      width: 240,
      valueFormatter: ({ value }) => getFundName(value),
    },
    {
      field: "transferAmount",
      headerName: "Amount",
      headerAlign: "center",
      width: 150,
      align: "right",
      valueFormatter: ({ value }) =>
        Intl.NumberFormat("en-us", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(value),
    },
    {
      field: "requestStatus",
      headerName: "Status",
      headerAlign: "center",
      width: 150,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return drawStatusChip(params.row.requestStatus);
      },
      valueFormatter: ({ value }) => getReqeustStatus(value)?.value,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      disableExport: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const onClick = (e: any) => {
          return navigate("/ftdetails/" + params.row.fundTransferRequestId);
        };

        return (
          <ButtonDX variant="outlined" size="small" onClick={onClick}>
            View
          </ButtonDX>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const { setError, setInfo } = useErrorContext();

  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAllFTRequests } = useFundTransferService();

  useEffect(() => {
    setIsLoading(true);

    getAllFTRequests()
      .then((ftReqs) => {
        setRows(ftReqs);
      })
      .catch((ex) => setError(ex))
      .finally(() => setIsLoading(false));
  }, []);

  const drawStatusChip = (statusId: number) => {
    switch (statusId) {
      case 1:
        return (
          <Chip
            variant="outlined"
            label={getReqeustStatus(statusId)?.value}
            size="small"
            color="info"
          />
        );
      case 2:
        return (
          <Chip
            variant="outlined"
            label={getReqeustStatus(statusId)?.value}
            size="small"
            color="warning"
          />
        );
      case 3:
        return (
          <Chip
            variant="outlined"
            label={getReqeustStatus(statusId)?.value}
            size="small"
            color="success"
          />
        );
      case 4:
        return (
          <Chip
            variant="outlined"
            label={getReqeustStatus(statusId)?.value}
            size="small"
            color="error"
          />
        );
      default:
        return (
          <Chip
            variant="outlined"
            label={getReqeustStatus(statusId)?.value}
            size="small"
          />
        );
    }
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12}>
        <DataGrid
          getRowId={(row) => row.fundTransferRequestId}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          loading={isLoading}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "requestDate", sort: "desc" }],
            },
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default FundTransferRequests;
