import useSecureAPI from "../hooks/usesecureapi";

export const useFundTransferService = () => {
  const secureAPI = useSecureAPI();

  const getAllFTRequests = async () => {
    const response = await secureAPI.get("FundTransferRequest");
    return response.data;
  };

  const getFTRequestDetails = async (ftRequestId: number) => {
    const response = await secureAPI.get("FundTransferRequest/" + ftRequestId);
    return response.data;
  };

  const updateStatus = async (updateStatusRequest: any) => {
    const response = await secureAPI.put(
      "FundTransferRequest/updatestatus",
      updateStatusRequest
    );
    return response.data;
  };

  return {
    getAllFTRequests,
    getFTRequestDetails,
    updateStatus,
  };
};
