import { TextField, InputAdornment, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";

const DiscTextFieldDX = (props: any) => {
  const helperText = props.helperText;
  const value =
    props.value instanceof moment
      ? moment(props.value).format("DD-MMM-yyyy")
      : props.value;

  return (
    <TextField
      {...props}
      value={value ?? ""}
      variant="outlined"
      fullWidth
      multiline
      readOnly
      placeholder={helperText}
      helperText=""
      InputLabelProps={{ style: { pointerEvents: "auto" }, shrink: true }}
      error={props.isdiscrepant}
      InputProps={{
        endAdornment: props.togglediscrepency && (
          <InputAdornment position="end">
            <IconButton onClick={() => props.togglediscrepency(props.name)}>
              {props.isdiscrepant ? <RefreshIcon /> : <CloseIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DiscTextFieldDX;
