import React from "react";

import {
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";

import DispTextFieldDX from "../controls/discrepency/disctextfielddx";
import GridDX from "../layout/griddx";

import { transformAndFilterInput } from "../../shared/global";

const FATCA = (props: any) => {
  const theme = useTheme();

  return (
    <GridDX container spacing={2}>
      <GridDX item xs={12}>
        <DispTextFieldDX
          label="Name (as show on your income tax return)"
          name="w9Name"
          value={props.data.w9Name}
          isdiscrepant={props.discrepencies["w9Name"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12}>
        <DispTextFieldDX
          label="Address in United States (if any)"
          name="w9Address"
          value={props.data.w9Address}
          isdiscrepant={props.discrepencies["w9Address"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={6} sx={{ flexDirection: "column" }}>
        <Typography style={{ fontWeight: "bold", marginBottom: 10 }}>
          Tax payer Identification Number (TIN){" "}
        </Typography>
        <DispTextFieldDX
          label="Social Security Number"
          name="w9SSN"
          value={props.data.w9SSN}
          isdiscrepant={props.discrepencies["w9SSN"]}
          togglediscrepency={props.togglediscrepency}
        />
        <Typography
          style={{ fontWeight: "bold", marginTop: 10, marginBottom: 10 }}
        >
          OR
        </Typography>
        <DispTextFieldDX
          label="Employer Identification Number"
          name="w9EIN"
          value={props.data.w9EIN}
          isdiscrepant={props.discrepencies["w9EIN"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} style={{ flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="isCertify"
                checked={props.data.isCertify}
                color="primary"
                disabled={props.readOnly}
              />
            }
            label=""
          />
          <Typography variant="body1" style={{ marginLeft: -10, fontSize: 14 }}>
            Under penalties of perjury, I certify that:
          </Typography>
        </div>
        <div>
          <ol style={{ textAlign: "justify", fontSize: 14 }}>
            <li>
              The number shown on this form is my correct taxpayer
              identification number (or I am waiting for a number to be issued
              to me); and
            </li>
            <li>
              I am not subject to backup withholding because:
              <ol type="a">
                <li>I am exempt from backup withholding, or</li>
                <li>
                  I have not been notified by the Internal Revenue Service (IRS)
                  that I am subject to backup withholding as a result of a
                  failure to report all interest or dividends, or
                </li>
                <li>
                  The IRS has notified me that I am no longer subject to backup
                  withholding tax; and
                </li>
              </ol>
            </li>
            <li>I am U.S. citizen or other U.S. person and</li>
            <li>
              The FATCA code(s) entered on this form (if any) indicating that I
              am exempt from FATCA report is correct.
            </li>
          </ol>
        </div>
      </GridDX>
    </GridDX>
  );
};

export default FATCA;
