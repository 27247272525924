import useSecureAPI from "../hooks/usesecureapi";

export const useRedemptionService = () => {
  const secureAPI = useSecureAPI();

  const getAllRedemptionRequests = async () => {
    const response = await secureAPI.get("RedemptionRequest");
    return response.data;
  };

  const getRedemptionDetails = async (redemptionId: number) => {
    const response = await secureAPI("RedemptionRequest/" + redemptionId);
    return response.data;
  };

  const updateStatus = async (updateStatusRequest: any) => {
    const response = await secureAPI.put(
      "RedemptionRequest/updatestatus",
      updateStatusRequest
    );
    return response.data;
  };

  return {
    getAllRedemptionRequests,
    getRedemptionDetails,
    updateStatus,
  };
};
