import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import DashboardTemplate from "./templates/dashboardtemplate";
import AccountTemplate from "./templates/accounttemplate";

import Account from "./pages/account";
import Dashboard from "./pages/dashboard";
import ApplicationDetails from "./pages/applicationdetails";

import { AuthProvider, useAuthContext } from "./context/authcontext";
import { useConfigContext } from "./context/configcontext";

import Loading from "./components/loading";

import { initializeEssentialData, initializeListData } from "./shared/lookups";
import { ErrorContextProvider } from "./context/errorcontext";
import Investments from "./pages/investments";
import InvestmentDetails from "./pages/investmentdetails";
import Redemptions from "./pages/redemptions";
import RedemptionDetails from "./pages/redemptiondetails";
import FundTransferRequests from "./pages/fundtransferrequests";
import FTDetails from "./pages/fundstransferdetails";
import FundsTransferDetails from "./pages/fundstransferdetails";
import ProtectedRoute from "./components/route/proetectedroute";

function App() {
  return (
    <ErrorContextProvider>
      <AuthProvider>
        <ApplicationRoutes />
      </AuthProvider>
    </ErrorContextProvider>
  );
}

const ApplicationRoutes = () => {
  const { configLoaded, API_URL } = useConfigContext();
  const { isLoggedIn } = useAuthContext();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoading && configLoaded) loadInitialData();
  }, [isLoading, configLoaded, API_URL]);

  const loadInitialData = async () => {
    await initializeEssentialData();
    await initializeListData("");
    setLoading(false);
  };

  if (isLoading) return <Loading />;
  else
    return (
      <Router>
        <Routes>
          <Route element={<AccountTemplate />}>
            <Route path="/" element={<Account />} />
          </Route>
          <Route
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <DashboardTemplate />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/investments" element={<Investments />} />
            <Route path="/redemptions" element={<Redemptions />} />
            <Route path="/ftrequests" element={<FundTransferRequests />} />
          </Route>

          <Route
            path="/applicationdetails/:id"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <ApplicationDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/invdetails/:id"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <InvestmentDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reddetails/:id"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <RedemptionDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ftdetails/:id"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <FundsTransferDetails />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    );
};

export default App;
