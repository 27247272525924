import axios from "axios";
import { API_URL_1 } from "../../context/configcontext";
import { errorMsgResponseCodeList } from "../global";

/* 
EXAMPLE CALL
  
  const response = await apiCall.get("client");
  return response.data;
  
*/

export const apiCall = axios.create({
  baseURL: API_URL_1,
});

export const secureAPI = axios.create({
  baseURL: API_URL_1,
});

// Add a response interceptor
apiCall.interceptors.response.use(
  function (response) {
    console.log({ response });
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },

  async function (error) {
    console.log({ error });

    if (!error.response) return Promise.reject(error.message);

    if (errorMsgResponseCodeList.includes(error.response.status)) {
      return Promise.reject(error.message);
    }

    const errorMessage = error?.response?.data ?? error;

    if (errorMessage.Message) return Promise.reject(errorMessage.Message);
    else if (typeof errorMessage === "object") {
      console.log(typeof errorMessage);
      const message = await errorMessage.text();
      return Promise.reject(JSON.parse(message).Message);
    } else return Promise.reject(errorMessage);
  }
);
