import React, { useState, useEffect } from "react";
import { Container, CircularProgress } from "@mui/material";
import BoxDX from "./layout/boxdx";

const LoadingOverlay = () => {
  return (
    <BoxDX
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        backgroundColor: "black",
        opacity: 0.7,
        top: 0,
        zIndex: 200,
        width: "100%",
      }}
    >
      <CircularProgress />
    </BoxDX>
  );
};

export default LoadingOverlay;
