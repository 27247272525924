import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GridDX from "../layout/griddx";
import Nominee from "./nominee";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import { useAuthContext } from "../../context/authcontext";
import { useUserApplicationService } from "../../shared/services/userapplicationservice";

const ContributionNominee = (props: any) => {
  const { getUserApplicationNominees } = useUserApplicationService();

  const theme = useTheme();
  const useMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const [nomineesData, setNomineesData] = useState<any>([]);
  const [totalNominees, setTotalNominees] = useState(1);
  const [disableAddButton, setDisableAddButton] = useState(false);

  const addNomineeData = (index: number, data: any) => {
    setNomineesData([
      ...nomineesData.slice(0, index),
      data,
      ...nomineesData.slice(index + 1),
    ]);
  };

  const getUserAppNominees = async () => {
    getUserApplicationNominees(props.data.userApplicationId).then(
      (response: any) => {
        setNomineesData(response);
        setTotalNominees(response.length);
      }
    );
  };

  useEffect(() => {
    if (props.data && nomineesData.length === 0) {
      getUserAppNominees();
    }
  }, [props.data]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GridDX container spacing={props.readOnly ? 2 : useMobileView ? 2 : 4}>
        {(() => {
          const arr = [];
          for (let i = 0; i < totalNominees; i++) {
            arr.push(
              <Nominee
                key={"key_nom_" + i}
                readOnly={props.readOnly}
                index={i}
                onChangeData={addNomineeData}
                data={props.data}
                nominee={nomineesData[i]}
                discrepencies={props.discrepencies}
                togglediscrepency={props.togglediscrepency}
              />
            );
          }
          return arr;
        })()}
      </GridDX>
    </LocalizationProvider>
  );
};

export default ContributionNominee;
