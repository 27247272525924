import React, { useEffect, useState, useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
} from "@mui/material";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import YesNoSwitchDX from "../controls/yesnoswitchdx";

import GridDX from "../layout/griddx";

const Personal = (props: any) => {
  return (
    <GridDX container spacing={2}>
      <GridDX item xs={12} sm={6} sx={{ flexDirection: "column" }}>
        <DiscTextFieldDX
          label="Residential Address"
          name="residentialAddress"
          value={props.data.residentialAddress}
          isdiscrepant={props.discrepencies["residentialAddress"]}
          togglediscrepency={props.togglediscrepency}
        />
        <GridDX container sx={{ marginTop: 1 }} spacing={1}>
          <GridDX item xs={10}>
            <Typography style={{ fontSize: "12px", textAlign: "justify" }}>
              Is Your residential Address same as address mentioned on your
              Identification Document (CNIC / SNIC / NICOP / SNICOP / POC)
            </Typography>
          </GridDX>
          <GridDX item xs={2} justifyContent="flex-end">
            <YesNoSwitchDX
              name="isResidentAdressSameAsCNIC"
              readOnly={true}
              checked={props.data.isResidentAdressSameAsCNIC}
            />
          </GridDX>
        </GridDX>
      </GridDX>
      <GridDX item xs={12} sm={6} sx={{ flexDirection: "column" }}>
        <DiscTextFieldDX
          label="Mailing Address"
          name="mailingAddress"
          value={props.data.mailingAddress}
          isdiscrepant={props.discrepencies["mailingAddress"]}
          togglediscrepency={props.togglediscrepency}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled
              name="mailingSameAsResidential"
              color="primary"
              checked={props.data.mailingSameAsResidential}
            />
          }
          label="Same as Residential Address"
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Residential Status"
          name="residentialStatusId"
          value={props.data.residentialStatusId?.value}
          isdiscrepant={props.discrepencies["residentialStatusId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Country of Residence"
          name="countryOfResidenceId"
          value={props.data.countryOfResidenceId?.value}
          isdiscrepant={props.discrepencies["countryOfResidenceId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="City of Residence"
          name="cityOfResidenceId"
          value={props.data.cityOfResidenceId?.value}
          isdiscrepant={props.discrepencies["cityOfResidenceId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Area"
          name="areaId"
          value={props.data.areaId?.value}
          isdiscrepant={props.discrepencies["areaId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Nationality"
          name="nationalityId"
          value={props.data.nationalityId?.value}
          isdiscrepant={props.discrepencies["nationalityId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Country of Birth"
          name="countryOfBirthId"
          value={props.data.countryOfBirthId?.value}
          isdiscrepant={props.discrepencies["countryOfBirthId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="City of Birth"
          name="cityOfBirthId"
          value={props.data.cityOfBirthId?.value}
          isdiscrepant={props.discrepencies["cityOfBirthId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={10}>
        <Typography style={{ fontSize: "13px", textAlign: "justify" }}>
          Myself or any of my Family Member and Closed Associate are Politically
          Exposed Person (PEP)
        </Typography>
      </GridDX>
      <GridDX item xs={2} justifyContent="flex-end">
        <YesNoSwitchDX
          name="isPoliticallyExposedPerson"
          readOnly={true}
          checked={props.data.isPoliticallyExposedPerson}
        />
      </GridDX>
      <GridDX item xs={10}>
        <Typography style={{ fontSize: "13px", textAlign: "justify" }}>
          I wish to deduct my zakat from my Account Balance as per applicable
          law of Pakistan.
        </Typography>
      </GridDX>
      <GridDX item xs={2} justifyContent="flex-end">
        <YesNoSwitchDX
          name="isZakatDeduction"
          readOnly={true}
          checked={props.data.isZakatDeduction}
        />
      </GridDX>
      {!props.data.isZakatDeduction ? (
        <GridDX item xs={10}>
          <FormControlLabel
            control={<Radio color="primary" checked={true} disabled={true} />}
            style={{ textAlign: "justify" }}
            label={
              props.data.isNonMuslim === "true"
                ? "I am Non-Muslim and I am not liable to pay Zakat"
                : "I will provide/upload zakat Affidavit (Cz-50) for Zakat Exemption"
            }
          />
        </GridDX>
      ) : null}
      <GridDX item xs={10}>
        <Typography style={{ fontSize: "13px", textAlign: "justify" }}>
          Are you a U.S. resident or a U.S. citizen or a U.S. permanent resident
          card holder or a registered U.S tax payer?
        </Typography>
      </GridDX>
      <GridDX item xs={2} justifyContent="flex-end">
        <YesNoSwitchDX
          name="isUSResident"
          readOnly={true}
          checked={props.data.isUSResident}
        />
      </GridDX>
      <GridDX item xs={10}>
        <Typography style={{ fontSize: "13px", textAlign: "justify" }}>
          Are you a tax resident in any country other than Pakistan and United
          States of America?
        </Typography>
      </GridDX>
      <GridDX item xs={2} justifyContent="flex-end">
        <YesNoSwitchDX
          name="isNonPakTaxResident"
          readOnly={true}
          checked={props.data.isNonPakTaxResident}
        />
      </GridDX>
    </GridDX>
  );
};

export default Personal;
