import React, { useEffect, useState } from "react";
import { Typography, AppBar, IconButton, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import BellIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";

const DashboardBoardAppBarDX = () => {
  const routeMap = new Map<string, string>([
    ["dashboard", "Dashboard"],
    ["investments", "Investment Requests"],
    ["invdetails", "Investment Request Details"],
    ["redemptions", "Redemption Requests"],
    ["reddetails", "Redemption Request Details"],
    ["ftrequests", "Funds Transfer Requests"],
    ["ftdetails", "Funds Transfer Details"],
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const [pageHeading, setPageHeading] = useState("Dashboard");

  useEffect(() => {
    console.log(location);
    let pathName = location.pathname.substring(1);
    const idPath = pathName.indexOf("/");
    if (idPath != -1) {
      pathName = pathName.substring(0, idPath);
    }

    setPageHeading(routeMap.get(pathName) ?? "Page Title");
  }, [location.pathname]);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#007A48" }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {pageHeading}
        </Typography>
        <IconButton size="large" color="inherit">
          <BellIcon />
        </IconButton>
        <IconButton
          size="large"
          color="inherit"
          aria-label="account of current user"
          onClick={() => {
            console.log("im here");
            navigate("/");
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardBoardAppBarDX;
