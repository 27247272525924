import React, { useEffect, useState } from "react";
import {
  Typography,
  Theme,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarsIcon from "@mui/icons-material/StarRate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Launch } from "@mui/icons-material";
import { Document, Page } from "react-pdf";

import Loading from "../loading";
import GridDX from "../layout/griddx";

import { useAuthContext } from "../../context/authcontext";

import DiscDocument from "../controls/discrepency/disdocument";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = (theme: Theme) => {
  return {
    docSection: {
      width: "100%",
      padding: 2,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
    pdfIcon: {
      color: theme.palette.primary.main,
      fontSize: 80,
    },
    docTitleSection: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
    },
    docAbsent: {
      color: "gray",
      fontSize: "20px",
      marginRight: 5,
    },
    docPresent: {
      color: "green",
      fontSize: "20px",
      marginRight: 5,
    },
    docTitle: {
      color: "gray",
      fontWeight: "bold",
    },
    textSize: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "13px",
      },
    },
    requiredRed: {
      color: "white",
      backgroundColor: "red",

      borderRadius: "100%",
      marginRight: 5,
    },
    requiredGrey: {
      color: "white",
      backgroundColor: "grey",
      borderRadius: "100%",
      marginRight: 5,
    },
  };
};

const DocUpload = (props: any) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isLoading, setIsLoading] = useState(false);
  const [localDocumentList, setLocalDocumentList] = useState([]);
  const [showDoc, setShowDoc] = useState(false);
  const [document, setDocument] = useState("");

  useEffect(() => {
    // if (localDocumentList.length > 0) return;
    // setIsLoading(true);
    // getUserApplicationDocuments(props.data.userApplicationId)
    //   .then((docs) => {
    //     const newDocumentList: any = [];
    //     docs.forEach((doc: any) => {
    //       let docImage = "";
    //       if (doc.document && doc.docType)
    //         docImage =
    //           (doc.docType.includes("pdf")
    //             ? "data:application/pdf;base64,"
    //             : "data:image/jpeg;base64,") + doc.document;
    //       newDocumentList.push({
    //         ...doc,
    //         title: doc.shortName,
    //         document: docImage,
    //         modified: false,
    //       });
    //     });
    //     setLocalDocumentList(newDocumentList);
    //   })
    //   .catch((ex: any) => console.log(ex))
    //   .finally(() => setIsLoading(false));
  }, []);

  const renderDocumentSection = (docDetails: any, key: any) => {
    let urlObj = null;

    if (docDetails.document)
      if (docDetails.document instanceof File)
        urlObj = URL.createObjectURL(docDetails.document);
      else urlObj = docDetails.document;

    return (
      <GridDX key={"doc_grid_key_" + key} item xs={12} sx={{ my: 1 }}>
        <GridDX container sx={classes.docSection}>
          <GridDX item xs={11}>
            <GridDX container sx={{ width: "100%" }}>
              <GridDX item xs={7} alignItems="center">
                {docDetails.document === "" ? (
                  <CheckCircleIcon sx={classes.docAbsent} />
                ) : (
                  <CheckCircleIcon sx={classes.docPresent} />
                )}
                {docDetails.isMandatory && (
                  <StarsIcon
                    sx={
                      docDetails.document === ""
                        ? classes.requiredRed
                        : classes.requiredGrey
                    }
                  />
                )}
                <Typography variant="body1" sx={classes.docTitle}>
                  {key + "." + docDetails.title}
                </Typography>
              </GridDX>
              <GridDX item xs={5} justifyContent="flex-end">
                {docDetails.document && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        overflowWrap: "anywhere",
                        backgroundColor: "#efefef",
                        padding: 5,
                      }}
                    >
                      {docDetails.document.split(";")[0].split(":")[1] !==
                      "application/pdf" ? (
                        <img src={urlObj} height={100} alt={docDetails.title} />
                      ) : (
                        <PictureAsPdfIcon sx={classes.pdfIcon} />
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        overflowWrap: "anywhere",
                        padding: 5,
                      }}
                    >
                      <Launch
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setDocument(docDetails.document);
                          setShowDoc(true);
                        }}
                      />
                    </div>
                  </>
                )}
              </GridDX>
            </GridDX>
          </GridDX>
          <GridDX item xs={1} justifyContent="flex-end" alignItems="center">
            <DiscDocument
              name={"doc_" + docDetails.documentId}
              isdiscrepant={props.discrepencies["doc_" + docDetails.documentId]}
              togglediscrepency={props.togglediscrepency}
            />
          </GridDX>
        </GridDX>
      </GridDX>
    );
  };

  const renderDocumentPopup = () => {
    const documentToView = document as string;

    if (documentToView?.split(";")[0].split(":")[1] === "application/pdf")
      return (
        <Document file={`${documentToView}`}>
          <Page pageNumber={1} renderTextLayer={false} />
        </Document>
      );
    else return <img src={documentToView} style={{ width: "100%" }} />;
  };

  return (
    <GridDX
      container
      alignContent="center"
      justifyContent="center"
      spacing={2}
      sx={{ flex: 1 }}
    >
      {isLoading && <Loading />}

      <Dialog
        fullScreen
        open={showDoc}
        onClose={() => {
          setShowDoc(false);
          setDocument("");
        }}
      >
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <GridDX container sx={{ height: "100%" }} alignItems="flex-start">
            <GridDX item xs={12}>
              <IconButton
                size="large"
                onClick={() => {
                  setShowDoc(false);
                  setDocument("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </GridDX>
            <GridDX
              item
              xs={12}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showDoc && document && renderDocumentPopup()}
            </GridDX>
          </GridDX>
        </DialogContent>
      </Dialog>

      {props.documentList.map((x: any, index: number) =>
        renderDocumentSection(x, index + 1)
      )}
    </GridDX>
  );
};

export default DocUpload;
