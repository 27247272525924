import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme, Divider, Typography } from "@mui/material/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import GridDX from "../layout/griddx";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import { useAuthContext } from "../../context/authcontext";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const Nominee = (props: any) => {
  const { getUserDetails, inDiscrepancyMode, isDiscrepantField } =
    useAuthContext();

  const theme = useTheme();
  const useMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  let nomData = {
    id: 0,
    name: null,
    relationship: null,
    share: 0,
    cnicNumber: null,
    residentialAddress: null,
    telephoneNumber: null,
    bankDetails: null,
    userApplicationId: props.data.userApplicationId,
  };

  const [nomineeData, setNomineeData] = useState<any | null>(nomData);

  useEffect(() => {
    if (props.nominee) {
      setNomineeData(props.nominee);
    }
  }, [props.nominee]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GridDX container style={{ marginTop: "5px" }}>
        <GridDX item xs={12}>
          <Typography color="primary">Nominee {props.index + 1}</Typography>
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="name"
            name="name"
            label="Name"
            value={nomineeData.name || ""}
            required
            isdiscrepant={props.discrepencies["name"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="relationship"
            name="relationship"
            label="Relationship"
            value={nomineeData.relationship || ""}
            required
            isdiscrepant={props.discrepencies["relationship"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="share"
            name="share"
            label="Share"
            value={nomineeData.share || ""}
            required
            type="number"
            // readOnly={props.readOnly}
            isdiscrepant={props.discrepencies["share"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="cnic"
            name="cnic"
            label="CNIC/NICOP/B Form No."
            value={nomineeData.cnic || ""}
            required
            // readOnly={props.readOnly}
            isdiscrepant={props.discrepencies["cnic"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="residentialAddress"
            name="residentialAddress"
            label="Residential Address"
            value={nomineeData.residentialAddress || ""}
            required
            readOnly={props.readOnly}
            isdiscrepant={props.discrepencies["residentialAddress"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="telephoneNumber"
            name="telephoneNumber"
            label="Telephone Number"
            value={nomineeData.telephoneNumber || ""}
            // readOnly={props.readOnly}
            isdiscrepant={props.discrepencies["telephoneNumber"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="bankAccountDetail"
            name="bankAccountDetail"
            label="Bank Account Detail"
            value={nomineeData.bankAccountDetail || ""}
            required
            // readOnly={props.readOnly}
            isdiscrepant={props.discrepencies["bankAccountDetail"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
      </GridDX>
    </LocalizationProvider>
  );
};

export default Nominee;
