import { Divider } from "@mui/material";

const GridRowDividerDX = (props: any) => {
  return (
    <Divider
      sx={{
        width: "100%",
        borderColor: "rgba(255, 0, 0, 0.08)",
        my: 2,
        ...props.sx,
      }}
    />
  );
};

export default GridRowDividerDX;
