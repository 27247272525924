import React, { useEffect, useState } from "react";
import { Typography, IconButton } from "@mui/material";

import DispTextFieldDX from "../controls/discrepency/disctextfielddx";

import GridDX from "../layout/griddx";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";

const KYC = (props: any) => {
  const [sourceOfIncome, setSourceOfIncome] = useState(
    props.data.sourceOfIncome
  );

  return (
    <GridDX container spacing={2} sx={{ flex: 1 }}>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Education"
          name="educationId"
          value={props.data.educationId?.value}
          isdiscrepant={props.discrepencies["educationId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Occupation"
          name="occupationId"
          value={props.data.occupationId?.value}
          isdiscrepant={props.discrepencies["occupationId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Profession"
          name="professionId"
          value={props.data.professionId?.value}
          isdiscrepant={props.discrepencies["professionId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Annual Income"
          name="annualIncomeId"
          value={props.data.annualIncomeId?.value}
          isdiscrepant={props.discrepencies["annualIncomeId"]}
          togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <GridDX container sx={{ flex: 1 }}>
          {sourceOfIncome.map((d: any, index: number) => (
            <GridDX item xs={12} key={"inc_" + index}>
              <DiscTextFieldDX
                label="Regular Source of Income/Funds"
                name="sourceOfIncome"
                value={d?.value}
                style={{
                  marginBottom: index <= sourceOfIncome.length - 1 ? 10 : 0,
                  marginTop: index === 0 ? 0 : 10,
                }}
                isdiscrepant={props.discrepencies["sourceOfIncome"]}
                togglediscrepency={props.togglediscrepency}
              />
            </GridDX>
          ))}
        </GridDX>
      </GridDX>
    </GridDX>
  );
};

export default KYC;
