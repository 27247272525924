import React, { useEffect, useState, useContext } from "react";
import {
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";
import YesNoSwitchDX from "../controls/yesnoswitchdx";
import GridDX from "../layout/griddx";

const AdditionalKYC = (props: any) => {
  const theme = useTheme();
  const [showPEP, setShoWPep] = useState(false);

  useEffect(() => {
    setShoWPep(props.data.showPep);
  }, []);

  return (
    <GridDX container spacing={2}>
      <GridDX item xs={12} style={{ textAlign: "justify" }}>
        Are you/your family member/your close associate OR have you/your family
        member/your close assoicate ever been entrusted with the following
        functions either in Pakistan or Aboard?
      </GridDX>
      <GridDX item xs={12}>
        <List style={{ width: "100%" }}>
          <ListItem>
            <ListItemText primary="1. HEAD OF STATE" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isHeadOfState"
                checked={props.data.isHeadOfState}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="2. HEAD OF GOVERNMENT" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isHeadOfGovt"
                checked={props.data.isHeadOfGovt}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="3. SENIOR POLITICIAN" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorPolitician"
                checked={props.data.isSeniorPolitician}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="4. SENIOR GOVERNMENT OFFICIAL" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorGovtOfficial"
                checked={props.data.isSeniorGovtOfficial}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="5. SENIOR JUDICIAL OFFICIAL" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorJudicialOfficial"
                checked={props.data.isSeniorJudicialOfficial}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="6. SENIOR MILITARY OFFICIAL" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorMilitaryOfficial"
                checked={props.data.isSeniorMilitaryOfficial}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="7. SENIOR EXECUTIVE OF STATE OWNED CORPORATIONS" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorExecSOC"
                checked={props.data.isSeniorExecSOC}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="8. IMPORTANT POLITICAL PARTY OFFICIAL" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isImportantPoliticalPartyOfficial"
                checked={props.data.isImportantPoliticalPartyOfficial}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="9. SENIOR EXECUTIVE OF INTERNATIONAL ORGANIZATION" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isSeniorExecIO"
                checked={props.data.isSeniorExecIO}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="10. MEMBER OF THE BOARD OF INT'L ORGANIZATION" />
            <ListItemIcon>
              <YesNoSwitchDX
                name="isMemberOfBOIO"
                checked={props.data.isMemberOfBOIO}
                readOnly={true}
              />
            </ListItemIcon>
          </ListItem>
        </List>
      </GridDX>
      {showPEP && (
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            label="Nature of PEP"
            name="natureOfPEPId"
            value={props.data.natureOfPEPId?.value}
            isdiscrepant={props.discrepencies["natureOfPEPId"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
      )}

      {showPEP && props.data.natureOfPEPId && (
        <>
          {props.data.natureOfPEPId?.id === 2 && (
            <GridDX item xs={12} sm={6}>
              <DiscTextFieldDX
                label="Name Of Close Family Member"
                name="pepNameOfFamilyMember"
                value={props.data.pepNameOfFamilyMember}
                isdiscrepant={props.discrepencies["pepNameOfFamilyMember"]}
                togglediscrepency={props.togglediscrepency}
              />
            </GridDX>
          )}

          {props.data.natureOfPEPId.id === 3 && (
            <GridDX item xs={12} sm={6}>
              <DiscTextFieldDX
                label="Name Of Closed Associate"
                name="pepNameOfCloseAssociate"
                value={props.data.pepNameOfCloseAssociate}
                isdiscrepant={props.discrepencies["pepNameOfCloseAssociate"]}
                togglediscrepency={props.togglediscrepency}
              />
            </GridDX>
          )}

          <GridDX item xs={12} sm={6}>
            <DiscTextFieldDX
              label="Name of Department / Company"
              name="pepNatureOfDepartment"
              value={props.data.pepNatureOfDepartment}
              isdiscrepant={props.discrepencies["pepNatureOfDepartment"]}
              togglediscrepency={props.togglediscrepency}
            />
          </GridDX>
          <GridDX item xs={12} sm={6}>
            <DiscTextFieldDX
              label="Designation"
              name="pepDesignation"
              value={props.data.pepDesignation}
              isdiscrepant={props.discrepencies["pepDesignation"]}
              togglediscrepency={props.togglediscrepency}
            />
          </GridDX>
          <GridDX item xs={12} sm={6}>
            <DiscTextFieldDX
              label="Grade / Rank"
              name="pepGrade"
              value={props.data.pepGrade}
              isdiscrepant={props.discrepencies["pepGrade"]}
              togglediscrepency={props.togglediscrepency}
            />
          </GridDX>
        </>
      )}
    </GridDX>
  );
};

export default AdditionalKYC;
