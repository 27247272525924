import React, { useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import {
//   getPlanListByAccountCategory,
//   modeOfPaymentList,
//   modeOfContributionList,
//   contributionFrequencyList,
// } from "../../shared/lookups";

import { useAuthContext } from "../../context/authcontext";
import GridDX from "../layout/griddx";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";

const Contribution = (props: any) => {
  const { getUserDetails, inDiscrepancyMode, isDiscrepantField } =
    useAuthContext();

  const theme = useTheme();
  const useMobileView = useMediaQuery(theme.breakpoints.down("xs"));
  // const { errors, validateField, validatevpsSubFundValues } =
  //   useValidationContext();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GridDX container spacing={props.readOnly ? 2 : useMobileView ? 2 : 4}>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="modeOfContribution"
            name="modeOfContribution"
            label="Mode of Contribution"
            value={props.data.modeOfContribution?.value}
            isdiscrepant={props.discrepencies["modeOfContribution"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="initialContributionAmount"
            name="initialContributionAmount"
            label="Initial Contribution Amount"
            value={props.data?.initialContributionAmount}
            isdiscrepant={props.discrepencies["initialContributionAmount"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="amountInWords"
            name="amountInWords"
            label="Amount In Words"
            value={props.data.amountInWords}
            isdiscrepant={props.discrepencies["amountInWords"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="frontEndLoad"
            name="frontEndLoad"
            label="Front End Load (%)"
            value={props.data.frontEndLoad}
            isdiscrepant={props.discrepencies["frontEndLoad"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="contributionPaymentMode"
            name="contributionPaymentMode"
            label="Mode of Payment"
            value={props.data.contributionPaymentMode?.value}
            isdiscrepant={props.discrepencies["contributionPaymentMode"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            name="contributionReferenceNumber"
            value={props.data.contributionReferenceNumber}
            label="Cheque/DD/PO/Ref. No."
            isdiscrepant={props.discrepencies["contributionReferenceNumber"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            name="drawnOn"
            value={props.data.drawnOn}
            label="Drawn On"
            isdiscrepant={props.discrepencies["drawnOn"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            id="contributionFrequency"
            name="contributionFrequency"
            value={props.data.contributionFrequency?.value}
            label="Contribution Frequency"
            isdiscrepant={props.discrepencies["contributionFrequency"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            name="periodicContributionAmount"
            value={props.data.periodicContributionAmount}
            label="Periodic Contribution Amount"
            isdiscrepant={props.discrepencies["periodicContributionAmount"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
        <GridDX item xs={12} sm={6}>
          <DiscTextFieldDX
            name="yearlyContributionAmount"
            value={props.data.yearlyContributionAmount}
            label="Yearly Contribution Amount"
            isdiscrepant={props.discrepencies["yearlyContributionAmount"]}
            togglediscrepency={props.togglediscrepency}
          />
        </GridDX>
      </GridDX>
    </LocalizationProvider>
  );
};

export default Contribution;
