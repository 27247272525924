import { useEffect, useState } from "react";
import {
  Typography,
  AppBar,
  IconButton,
  Toolbar,
  Divider,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ApproveAppIcon from "@mui/icons-material/FactCheck";
import RejectAppIcon from "@mui/icons-material/Block";

const SecondaryAppBarDX = (props: any) => {
  const routeMap = new Map<string, string>([
    ["dashboard", "Dashboard"],
    ["applicationdetails", "Application Details"],
    ["investments", "Investment Requests"],
    ["invdetails", "Investment Request Details"],
    ["redemptions", "Redemption Requests"],
    ["reddetails", "Redemption Request Details"],
    ["ftrequests", "Funds Transfer Requests"],
    ["ftdetails", "Funds Transfer Details"],
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const [pageHeading, setPageHeading] = useState("Application Details");

  useEffect(() => {
    let pathName = location.pathname.substring(1);
    const idPath = pathName.indexOf("/");
    if (idPath != -1) {
      pathName = pathName.substring(0, idPath);
    }

    setPageHeading(routeMap.get(pathName) ?? "Page Title");
  }, [location.pathname]);

  return (
    <AppBar position="static" sx={{ backgroundColor: "#007A48" }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {pageHeading}
        </Typography>
        <Tooltip title="Save Discrepency">
          <IconButton
            size="large"
            color="inherit"
            disabled={!props.postDiscrpency}
            onClick={props.postDiscrpency ?? null}
          >
            <AppRegistrationIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Approve Application">
          <IconButton
            size="large"
            color="inherit"
            disabled={!props.approveApplication}
            onClick={props.approveApplication ?? null}
          >
            <ApproveAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reject Application">
          <IconButton
            size="large"
            color="inherit"
            disabled={!props.rejectApplication}
            onClick={props.rejectApplication ?? null}
          >
            <RejectAppIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Post data to IT Minds">
          <IconButton
            size="large"
            color="inherit"
            disabled={!props.postData}
            onClick={props.postData ?? null}
          >
            <CloudUploadIcon />
          </IconButton>
        </Tooltip>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ backgroundColor: "white", mx: 2 }}
        />
        <Tooltip title="Close">
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              navigate(-1);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default SecondaryAppBarDX;
