import React, { createContext, useContext } from "react";
import { ReactNode } from "react";

const AuthContext = createContext<any | null>(null);
const useAuthContext = () => useContext(AuthContext);

interface AuxProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuxProps) => {
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            isLogedIn: true,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isLoggedIn: true,
            userData: action.userData,
            userToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoggedIn: false,
            userData: null,
            userToken: null,
            discrepantData: null,
          };
      }
    },
    {
      isLoggedIn: false,
      userData: null,
      userToken: null,
    }
  );

  const signIn = async (data: any, token: string) => {
    const encodedToken = window.btoa(token);

    localStorage.setItem("onboardAdminSession", JSON.stringify(data));
    dispatch({
      type: "SIGN_IN",
      userData: data,
      token: token,
    });
  };

  const signOut = () => {
    localStorage.removeItem("onboardAdminSession");
    dispatch({ type: "SIGN_OUT" });
  };

  const getUserDetails = async () => {
    const sessionInfo = localStorage.getItem("onboardAdminSession");

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        const sessionInfoJSON = JSON.parse(sessionInfo);
        return sessionInfoJSON;
      } catch (ex) {
        console.log("Error parsing session info:", ex);
        return {};
      }
    }
  };

  const removeUserData = async () => {
    localStorage.removeItem("onboardAdminSession");
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.userToken,
        isLoggedIn: state.isLoggedIn,
        signIn,
        signOut,
        getUserDetails,
        removeUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
