import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import moment from "moment";

import { useErrorContext } from "../context/errorcontext";

import GridDX from "../components/layout/griddx";
import ButtonDX from "../components/controls/buttondx";

import { zeroPad } from "../shared/global";
import { useUserApplicationService } from "../shared/services/userapplicationservice";

const Dashboard = () => {
  const columns: GridColumns = [
    { field: "id", headerName: "ID", width: 75 },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      headerAlign: "center",
      align: "center",
      valueFormatter: ({ value }) => moment(value).format("DD-MMM-yyyy"),
    },
    { field: "fullName", headerName: "Full Name", flex: 1, minWidth: 200 },
    { field: "cnic", headerName: "CNIC", width: 140 },
    {
      field: "folioNumber",
      headerName: "Folio",
      width: 100,
      valueFormatter: ({ value }) => (value ? zeroPad(value, 7) : ""),
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return drawStatusChip(params.row.statusId, params.row.statusDesc);
      },
      getApplyQuickFilterFn: (value: string) => {
        return (params: GridCellParams): boolean => {
          return params.row?.statusDesc?.toLowerCase().includes(value);
        };
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      sortable: false,
      disableExport: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const onClick = (e: any) => {
          const currentRow = params.row;
          console.log(params.row.id);
          return navigate("/applicationdetails/" + params.row.id);
        };

        return (
          <ButtonDX variant="outlined" size="small" onClick={onClick}>
            View
          </ButtonDX>
        );
      },
    },
  ];

  const statues = [
    "In Progress", // 0
    "Submitted", // 1
    "Discrepancy", // 2
    "Approved", // 3
    "Rejected", // 4
    "Posted", // 5
    "Inv Submitted",
    "Inv Approved",
    "Inv Rejected",
  ];

  const navigate = useNavigate();
  const { setError, setInfo } = useErrorContext();
  const { getAllApplications } = useUserApplicationService();

  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getAllApplications()
      .then((userApps) => {
        const newRows = userApps.map((r: any) => {
          return {
            id: r.userApplicationId,
            fullName: r.name,
            cnic: r.cnic,
            country: "Pakistan",
            city: "Karachi",
            statusId: r.applicationStatusId,
            statusDesc: statues[r.applicationStatusId],
            date: r.modifiedOn ?? r.createdOn,
          };
        });

        setRows(newRows);
      })
      .catch((ex) => setError(ex))
      .finally(() => setIsLoading(false));
  }, []);

  const drawStatusChip = (statusId: number, statusDesc: string) => {
    switch (statusId) {
      case 1:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="info"
          />
        );
      case 2:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="warning"
          />
        );
      case 3:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="success"
          />
        );
      case 4:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="error"
          />
        );
      case 5:
        return <Chip label={statusDesc} size="small" color="primary" />;
      case 6:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="info"
          />
        );
      case 7:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="success"
          />
        );
      case 8:
        return (
          <Chip
            variant="outlined"
            label={statusDesc}
            size="small"
            color="error"
          />
        );
      default:
        return <Chip variant="outlined" label={statusDesc} size="small" />;
    }
  };

  return (
    <GridDX container sx={{ width: "100%" }}>
      <GridDX item xs={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          loading={isLoading}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 20]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }],
            },
          }}
        />
      </GridDX>
    </GridDX>
  );
};

export default Dashboard;
