import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import AMCLogo from "../asset/pqamc_trans_logo.png";

import GridDX from "../components/layout/griddx";
import TextFieldDX from "../components/controls/textfielddx";
import ButtonDX from "../components/controls/buttondx";

import { useAuthContext } from "../context/authcontext";
import { adminLogin } from "../shared/services/auth";
import { useErrorContext } from "../context/errorcontext";

const Account = () => {
  const navigate = useNavigate();
  const { setError } = useErrorContext();
  const { signIn } = useAuthContext();

  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (loginData.username.length === 0)
      newErrors["username"] = "User name is required";
    if (loginData.password.length === 0)
      newErrors["password"] = "Password is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onLoginClick = () => {
    if (validateForm()) {
      setIsLoading(true);
      adminLogin(loginData.username, loginData.password)
        .then((data) => {
          console.log({ data });
          signIn(data, data.tokenInfo.token);
          navigate("/dashboard");
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <GridDX container width={{ width: 600 }}>
      <GridDX item xs={12} style={{ justifyContent: "center" }}>
        <img src={AMCLogo} alt="Logo" width={150} />
      </GridDX>
      <GridDX
        item
        xs={12}
        style={{ justifyContent: "center", flexDirection: "column" }}
      >
        <TextFieldDX
          name="username"
          label="User ID"
          value={loginData.username}
          style={{ marginTop: 8, marginBottom: 8 }}
          onChange={handleInputChange}
          error={errors["username"]}
        />
        <TextFieldDX
          name="password"
          label="Password"
          type="password"
          value={loginData.password}
          style={{ marginTop: 16 }}
          onChange={handleInputChange}
          error={errors["password"]}
        />
      </GridDX>
      <GridDX item xs={12} style={{ flexDirection: "column", marginTop: 16 }}>
        <ButtonDX variant="contained" onClick={onLoginClick}>
          Login
        </ButtonDX>
        <ButtonDX style={{ marginTop: 16, marginBottom: 8 }} variant="outlined">
          Forget Password
        </ButtonDX>
      </GridDX>
    </GridDX>
  );
};

export default Account;
