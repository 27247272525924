import React, { useEffect, useState } from "react";
import { Typography, useMediaQuery } from "@mui/material/";
import { useTheme } from "@mui/material/";

import GridDX from "../layout/griddx";

import YesNoSwitchDX from "../controls/yesnoswitchdx";
import TextFieldDX from "../controls/textfielddx";
import MaskInput from "../controls/maskinput/maskinput";

import FailedIcon from "@mui/icons-material/ClearRounded";
import SuccessIcon from "@mui/icons-material/Check";
import DiscTextFieldDX from "../controls/discrepency/disctextfielddx";

const Bank = (props: any) => {
  const theme = useTheme();

  const [isTitleFetched, setTitleFetched] = useState(false);

  useEffect(() => {
    if (props.data.oneLinkTitle)
      setTitleFetched(props.data.oneLinkTitle.length > 0);
    else setTitleFetched(false);
  }, []);

  const showVerificationStatus = () => {
    return isTitleFetched ? (
      <>
        <SuccessIcon
          style={{
            backgroundColor: "green",
            borderRadius: "50%",
            marginRight: 5,
            marginLeft: 5,
            color: "white",
            fontSize: 16,
          }}
        />
        <span style={{ color: "green" }}>Title fetched successfully</span>
      </>
    ) : (
      <>
        <FailedIcon
          style={{
            backgroundColor: "red",
            borderRadius: "50%",
            marginRight: 5,
            marginLeft: 5,
            color: "white",
            fontSize: 16,
          }}
        />
        <span style={{ color: "red" }}>Tile fetch unsuccessful</span>
      </>
    );
  };

  const toggleDiscrepencyForBank = () => {
    props.togglediscrepency("bankId");
    props.togglediscrepency("ibanNumber");
  };

  return (
    <GridDX container spacing={2} sx={{ flex: 1 }}>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Bank Name"
          name="bankId"
          value={props.data.bankId?.value}
          isdiscrepant={props.discrepencies["bankId"]}
          togglediscrepency={
            props.togglediscrepency ? toggleDiscrepencyForBank : null
          }
        />
      </GridDX>
      <GridDX item xs={12} sm={6}>
        <DiscTextFieldDX
          label="Bank/E-Wallet IBAN Number"
          name="ibanNumber"
          value={props.data.ibanNumber}
          isdiscrepant={props.discrepencies["ibanNumber"]}
          //togglediscrepency={props.togglediscrepency}
        />
      </GridDX>
      <GridDX
        item
        xs={12}
        style={{
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          {showVerificationStatus()}
        </div>
      </GridDX>

      <GridDX
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div
          style={{
            fontSize: 12,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {isTitleFetched && (
            <>
              <TextFieldDX
                disabled={true}
                label="Bank Account Title"
                value={props.data.oneLinkTitle}
                readOnly={props.readOnly}
              />
            </>
          )}
        </div>
      </GridDX>

      <GridDX item xs={6}>
        <Typography style={{ fontSize: 14, textAlign: "justify" }}>
          Do you use online banking
        </Typography>
      </GridDX>
      <GridDX item xs={6} justifyContent="flex-end">
        <YesNoSwitchDX
          name="useOnlineBanking"
          readOnly={props.readOnly}
          checked={props.data.useOnlineBanking}
        />
      </GridDX>
    </GridDX>
  );
};

export default Bank;
