import moment from "moment";

import {
  genderList,
  mobileOwnList,
  fundPreferenceList,
  residentialStatusList,
  countryList,
  cityList,
  birthCityList,
  areaList,
  bankList,
  educationList,
  incomeSourceList,
  professionList,
  occupationList,
  annualIncomeList,
  updateCities,
  updateBirthCities,
  updateAreas,
  natureOfPEPList,
  accountCategoryList,
  getPlanListByAccountCategory,
  retirementAgeList,
  fundList,
  modeOfContributionList,
  modeOfPaymentList,
  contributionFrequencyList,
} from "./lookups";

export const DATETIME_FORMAT = "DD-MMM-YYYY hh:mm A";

export const sampleRows = [
  {
    id: 1,
    fullName: "Abubakar Munawar",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 4,
    statusDesc: "Approved",
  },
  {
    id: 2,
    fullName: "Waseem Khan",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 3,
    statusDesc: "Discrepancy",
  },
  {
    id: 3,
    fullName: "Kashan Rafique Ahmed",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 2,
    statusDesc: "Submitted",
  },
  {
    id: 4,
    fullName: "Shaikh Asad",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 1,
    statusDesc: "In Progress",
  },
  {
    id: 5,
    fullName: "Muhammad Ummair",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 2,
    statusDesc: "Submitted",
  },
  {
    id: 6,
    fullName: "Bilal Rashid",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 4,
    statusDesc: "Approved",
  },
  {
    id: 7,
    fullName: "Babar Siddiqui",
    cnic: "42201-xxxxxx-x",
    country: "Pakistan",
    city: "Karachi",
    statusId: 3,
    statusDesc: "Discrepancy",
  },
];

export const totalSteps = [
  {
    id: 1,
    name: "Basic Information",
    required: true,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    active: false,
    complete: false,
    icon: "general",
  },
  {
    id: 2,
    name: "Personal Information",
    required: true,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 3,
    name: "Bank Details",
    required: true,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 4,
    name: "Know Your Customer",
    required: true,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 5,
    name: "Additional Information",
    required: false,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 6,
    name: "Additional Information - KYC",
    required: false,
    subtitle: "All information is mandatory",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 7,
    name: "FATCA",
    required: false,
    subtitle:
      "W-9 (Request for Taxpayer Identification Number & Certification)",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 8,
    name: "CRS-1",
    required: false,
    subtitle: "Click to fill required information",
    alterSubtitle: "Click to fill required information",
    complete: false,
    icon: "general",
  },
  {
    id: 9,
    name: "Document Upload",
    required: true,
    subtitle: "Please update required documents",
    alterSubtitle: "Please update required documents",
    complete: false,
    icon: "general",
  },
  {
    id: 10,
    name: "Account Application Review",
    required: true,
    subtitle: "Please review and verify all information entered",
    alterSubtitle: "Please review and verify all information entered",
    complete: false,
    icon: "preview",
  },
  {
    id: 11,
    name: "Declaration",
    required: true,
    subtitle: "Please accept terms & conditions",
    alterSubtitle: "Please accept terms & conditions",
    complete: false,
    icon: "preview",
  },
];

export const convertAPIDate = (apiDate: any) => {
  if (apiDate) return moment(apiDate); //keeping the dates in moment format;
  else return null;
};

const modesOfPayment = [
  { id: 1, value: "Cash" },
  { id: 2, value: "Cheque" },
  { id: 3, value: "IBFT" },
  { id: 4, value: "Online Transfer/IBFT" },
  { id: 5, value: "RTGS" },
  { id: 6, value: "Blinq (Online Payment)" },
];

export enum ApplicationStatus {
  Pending = 0,
  Submitted,
  Discrepancy,
  Approved,
  Rejected,
  Posted,
  InvestmentSubmitted,
  InvestmentApproved,
  InvestmentRejected,
  InvestmentCancelled, //?
}

export enum RequestStatus {
  Pending = 1,
  Approved,
  Rejected,
  Posted,
}

const requestStatus = [
  { id: 1, value: "Pending" },
  { id: 2, value: "Approved" },
  { id: 3, value: "Rejected" },
  { id: 4, value: "Posted" },
  { id: 5, value: "Paid" },
  { id: 6, value: "Cancelled" },
];

export const getModeOfPayment = (mode: number) => {
  const found = modesOfPayment.find((item) => {
    if (item.id === mode) return item;
  });
  return found?.value;
};

export const getReqeustStatus = (statusId: number) => {
  const found = requestStatus.find((item) => {
    if (item.id === statusId) return item;
  });
  return found;
};

export const getFundName = (fundId: number) => {
  const found = fundList.find((item: any) => item.id === fundId);
  
  return found ? found.value : ''; // Return the found value or an empty string if not found
};


const getFormDataLookupKey = (lookup: any, apiDataId: any) => {
  var dataList = [];

  if (apiDataId === "" || !apiDataId) return null;

  switch (lookup) {
    case "GENDER":
      if (genderList && genderList.length > 0) dataList = genderList.slice();
      break;

    case "MOBILE_OWN":
      if (mobileOwnList && mobileOwnList.length > 0)
        dataList = mobileOwnList.slice();
      break;

    case "FUND_PREF":
      if (fundPreferenceList && fundPreferenceList.length > 0)
        dataList = fundPreferenceList.slice();
      break;

    case "RES_STATUS":
      if (residentialStatusList && residentialStatusList.length > 0)
        dataList = residentialStatusList.slice();
      break;

    case "COUNTRY":
      if (countryList && countryList.length > 0) dataList = countryList.slice();
      break;

    case "CITY":
      if (cityList && cityList.length > 0) dataList = cityList.slice();
      break;

    case "CITYB":
      if (birthCityList && birthCityList.length > 0)
        dataList = birthCityList.slice();
      break;

    case "AREA":
      if (areaList && areaList.length > 0) dataList = areaList.slice();
      break;

    case "BANK":
      if (bankList && bankList.length > 0) dataList = bankList.slice();
      break;

    case "EDUCATION":
      if (educationList && educationList.length > 0)
        dataList = educationList.slice();
      break;

    case "PROFESSION":
      if (professionList && professionList.length > 0)
        dataList = professionList.slice();
      break;

    case "OCCUPATION":
      if (occupationList && occupationList.length > 0)
        dataList = occupationList.slice();
      break;

    case "PEP":
      if (natureOfPEPList && natureOfPEPList.length > 0)
        dataList = natureOfPEPList.slice();
      break;

    case "INCOME":
      if (annualIncomeList && annualIncomeList.length > 0)
        dataList = annualIncomeList.slice();
      break;

    case "RETIRE_AGE":
      if (retirementAgeList && retirementAgeList.length > 0)
        dataList = retirementAgeList.slice();
      break;

    case "ACCOUNT_CATEGORY":
      if (accountCategoryList && accountCategoryList.length > 0)
        dataList = accountCategoryList.slice();
      break;

    case "MODE_OF_CONTRIBUTION":
      if (modeOfContributionList && modeOfContributionList.length > 0)
        dataList = modeOfContributionList.slice();
      break;
    case "CONTRIBUTION_PAYMENT_MODE":
      if (modeOfPaymentList && modeOfPaymentList.length > 0)
        dataList = modeOfPaymentList.slice();
      break;
    case "CONTRIBUTION_FREQUENCY":
      if (contributionFrequencyList && contributionFrequencyList.length > 0)
        dataList = contributionFrequencyList.slice();
      break;

    default:
      break;
  }

  if (dataList && dataList.length > 0) {
    return dataList.find((x: any) => x.id === apiDataId);
  } else return null;
};

export const getIncomeSourceDataLookupKeys = (incomeData: any) => {
  if (!incomeData || incomeData.length === 0) return [0];
  else {
    return incomeData.map((x: any) => {
      if (x === 0) return 0;
      else return incomeSourceList.find((i: any) => i.id === Number(x));
    });
  }
};

export const translateAPIDataToFormData = async (apiData: any) => {
  if (apiData.countryOfResidentId)
    try {
      await updateCities(Number(apiData.countryOfResidentId));
    } catch (ex) {
      console.log("Error getting cities for :", apiData.countryOfResidentId);
      console.log(ex);
    }

  if (apiData.cityOfResidentId)
    try {
      await updateAreas(Number(apiData.cityOfResidentId));
    } catch (ex) {
      console.log("Error getting areas for :", apiData.cityOfResidentId);
      console.log(ex);
    }

  if (apiData.countryOfBirthId)
    try {
      await updateBirthCities(Number(apiData.countryOfBirthId));
    } catch (ex) {
      console.log("Error getting birth cities for :", apiData.countryOfBirthId);
      console.log(ex);
    }

  // let vpsFundCode = apiData.PlanSno
  //   ? await getPlanLookupKey(
  //       Number(apiData.AccountCategory),
  //       Number(apiData.PlanSno)
  //     )
  //   : "";

  let data: any = {
    ...apiData,
    accountCategoryId: getFormDataLookupKey(
      "ACCOUNT_CATEGORY",
      Number(apiData.accountCategoryId)
    ),
    genderId: getFormDataLookupKey("GENDER", apiData.genderId),
    dateOfBirth: convertAPIDate(apiData.dateOfBirth),
    cnicIssueDate: convertAPIDate(apiData.cnicIssueDate),
    cnicExpiryDate: convertAPIDate(apiData.cnicExpiryDate),
    contactOwnershipId: getFormDataLookupKey(
      "MOBILE_OWN",
      apiData.contactOwnershipId
    ),
    //fundPreference: getFormDataLookupKey("FUND_PREF", apiData.AcctPreference),
    //vpsfundId: vpsFundCode,

    residentialStatusId: getFormDataLookupKey(
      "RES_STATUS",
      apiData.residentialStatusId
    ),
    countryOfResidenceId: getFormDataLookupKey(
      "COUNTRY",
      Number(apiData.countryOfResidenceId)
    ),
    cityOfResidenceId: getFormDataLookupKey(
      "CITY",
      Number(apiData.cityOfResidenceId)
    ),
    areaId: getFormDataLookupKey("AREA", Number(apiData.areaId)),
    nationalityId: getFormDataLookupKey(
      "COUNTRY",
      Number(apiData.nationalityId)
    ),
    countryOfBirthId: getFormDataLookupKey(
      "COUNTRY",
      Number(apiData.countryOfBirthId)
    ),
    cityOfBirthId: getFormDataLookupKey("CITYB", Number(apiData.cityOfBirthId)),
    isNonMuslim: apiData.isNonMuslim.toString(), // IMPORTANT

    bankId: getFormDataLookupKey("BANK", apiData.bankId),

    educationId: getFormDataLookupKey("EDUCATION", apiData.educationId),
    professionId: getFormDataLookupKey("PROFESSION", apiData.professionId),
    sourceOfIncome: getIncomeSourceDataLookupKeys(apiData.sourceOfIncome),
    occupationId: getFormDataLookupKey(
      "OCCUPATION",
      Number(apiData.occupationId)
    ),
    annualIncomeId: getFormDataLookupKey("INCOME", apiData.annualIncomeId),

    countryOfTaxId: getFormDataLookupKey(
      "COUNTRY",
      Number(apiData.countryOfTaxId)
    ),
    tinReasonId: apiData.tinReasonId ? apiData.tinReasonId.toString() : "", // IMPORTANT due to radio control

    natureOfPEPId: getFormDataLookupKey("PEP", Number(apiData.natureOfPEPId)),
    allDocsUploaded: false,
    vpsFundId: apiData.vpsFundId
      ? await getPlanLookupKey(
          Number(apiData.accountCategoryId),
          Number(apiData.vpsFundId)
        )
      : "",
    modeOfContribution: getFormDataLookupKey(
      "MODE_OF_CONTRIBUTION",
      Number(apiData.modeOfContribution)
    ),
    contributionPaymentMode: getFormDataLookupKey(
      "CONTRIBUTION_PAYMENT_MODE",
      Number(apiData.contributionPaymentMode)
    ),
    contributionFrequency: getFormDataLookupKey(
      "CONTRIBUTION_FREQUENCY",
      Number(apiData.contributionPaymentMode)
    ),
  };

  data.showPep =
    data.isHeadOfState ||
    data.isHeadOfGovt ||
    data.isSeniorPolitician ||
    data.isSeniorGovtOfficial ||
    data.isSeniorJudicialOfficial ||
    data.isSeniorMilitaryOfficial ||
    data.isSeniorExecSOC ||
    data.isImportantPoliticalPartyOfficial ||
    data.isSeniorExecIO ||
    data.isMemberOfBOIO;

  return data;
};

export const cleanMaskedInputValue = (inputValue: any) => {
  if (!inputValue) return "";

  let newValue = inputValue.replace(/_/g, "").replace(/-/g, "");

  if (newValue === "PK") newValue = "";

  return newValue;
};

export const getBankIMD = (bankSNo: any) => {
  return bankSNo;
};

export const pad = (num: any) => {
  return ("0" + num).slice(-2);
};

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, "0");

export const formatTimerToMinAndSecs = (seconds: any) => {
  return pad(Math.floor(seconds / 60)) + ":" + pad(seconds % 60);
};

export const transformAndFilterInput = (e: any) => {
  let value = e.target.value;
  value = value.replace(/[^A-Za-z0-9.,/\- ]/gi, "");
  e.target.value = ("" + value).toUpperCase();
};

export const convertToAPIDate = (formDate: any) => {
  if (formDate) return moment(formDate).format();
  else return null;
};

export const VPSCategoryList = [2, 4];

const getPlanLookupKey = async (categoryId: any, planSno: any) => {
  return getPlanListByAccountCategory(categoryId).then((plans) => {
    let selectedPlan = plans.filter((item: any) => item.id === planSno);
    return selectedPlan ? selectedPlan[0] : "";
  });
};

export const errorMsgResponseCodeList = [400, 409];
