import useSecureAPI from "../hooks/usesecureapi";

export const useUserApplicationService = () => {
  const secureAPI = useSecureAPI();

  const getAllApplications = async () => {
    const response = await secureAPI.get("UserApplication");
    return response.data;
  };

  const getUserApplication = async (userApplicationId: number) => {
    const response = await secureAPI.get(
      "UserApplication/" + userApplicationId
    );
    return response.data;
  };

  const getUserApplicationDocuments = async (userApplicationId: number) => {
    const response = await secureAPI.get(
      "UserApplicationDocument/AllForUser/" + userApplicationId
    );
    return response.data;
  };

  const getUserApplicationNominees = async (userApplicationId: number) => {
    const response = await secureAPI.get(
      "UserApplicationNominee/application/" + userApplicationId
    );
    return response.data;
  };

  const saveDataDiscrepancy = async (
    userApplicationData: any,
    discrepantFields: string
  ) => {
    const response = await secureAPI.post("UserApplicationDiscrepancy", {
      application: userApplicationData,
      discrepantFields: discrepantFields,
    });

    return response.data;
  };

  const approveUserApplication = async (userApplicationId: number) => {
    const response = await secureAPI.put(
      "UserApplication/Approve/" + userApplicationId
    );
    return response.data;
  };

  const rejectUserApplication = async (userApplicationId: number) => {
    const response = await secureAPI.put(
      "UserApplication/Reject/" + userApplicationId
    );
    return response.data;
  };

  const postUserApplication = async (userApplicationId: number) => {
    const response = await secureAPI.put(
      "UserApplication/Post/" + userApplicationId
    );
    return response.data;
  };

  const approveInitialInvestment = async (userApplicationId: number) => {
    const response = await secureAPI.put(
      "UserApplication/ApproveInvestment/" + userApplicationId
    );
    return response.data;
  };

  const rejectInitialInvestment = async (userApplicationId: number) => {
    const response = await secureAPI.put(
      "UserApplication/RejectInvestment/" + userApplicationId
    );
    return response.data;
  };

  return {
    getAllApplications,
    getUserApplication,
    getUserApplicationDocuments,
    saveDataDiscrepancy,
    approveUserApplication,
    rejectUserApplication,
    postUserApplication,
    approveInitialInvestment,
    rejectInitialInvestment,
    getUserApplicationNominees,
  };
};
