import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useErrorContext } from "../context/errorcontext";
import {
  DATETIME_FORMAT,
  getFundName,
  RequestStatus,
  zeroPad,
} from "../shared/global";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DiscTextFieldDX from "../components/controls/discrepency/disctextfielddx";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { convertAPIDate } from "../shared/global";
import { getReqeustStatus } from "../shared/global";
import Loading from "../components/loading";
import NotificationBarDX from "../components/notificationbardx";
import GridDX from "../components/layout/griddx";
import SecondaryAppBarDX from "../components/appbars/secondaryappbar";
import LoadingOverlay from "../components/loadingoverlay";
import { useFundTransferService } from "../shared/services/fundtransferservice";

const FundsTransferDetails = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [ftDetails, setFTDetails] = useState<any | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const { setError, setInfo } = useErrorContext();
  const { getFTRequestDetails, updateStatus } = useFundTransferService();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    getFTRequestDetails(Number(id))
      .then(async (data) => {
        setFTDetails(data);
      })
      .catch((error) => {
        console.log("ERROR!", error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const updateFTReqStatus = async (statusId: number) => {
    setIsSaving(true);
    console.log("here updateFTReqStatus");
    updateStatus({ ...ftDetails, requestStatus: statusId })
      .then(async (response) => {
        console.log("response", response);
        setInfo("Status updated");
        await initialize();
      })
      .catch(async (error) => {
        console.log("error", error);
        setError(error);
        await initialize();
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isLoading ? (
        <Loading />
      ) : (
        <GridDX container spacing={2}>
          <NotificationBarDX />
          {isSaving && <LoadingOverlay />}
          <GridDX item xs={12}>
            <SecondaryAppBarDX
              postDiscrpency={null}
              approveApplication={
                ftDetails.requestStatus === RequestStatus.Pending
                  ? () => updateFTReqStatus(RequestStatus.Approved)
                  : null
              }
              rejectApplication={
                ftDetails.requestStatus === RequestStatus.Pending
                  ? () => updateFTReqStatus(RequestStatus.Rejected)
                  : null
              }
              postData={
                ftDetails.requestStatus === RequestStatus.Approved
                  ? () => updateFTReqStatus(RequestStatus.Posted)
                  : null
              }
            />
          </GridDX>

          <GridDX container sx={{ pl: 2, pr: 2 }}>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Request Date"
                name="requestDate"
                value={convertAPIDate(ftDetails.requestDate)?.format(
                  DATETIME_FORMAT
                )}
              />
            </GridDX>
            <GridDX item xs={6}>
              <DiscTextFieldDX
                label="User Name"
                name="userName"
                value={ftDetails.userName}
              />
            </GridDX>
            <GridDX item xs={3}>
              <DiscTextFieldDX
                label="CNIC"
                name="cnic"
                value={ftDetails.cnic}
              />
            </GridDX>
            <GridDX item xs={3}>
              <DiscTextFieldDX
                label="Folio Number"
                name="folioNumber"
                value={zeroPad(ftDetails.folioNumber, 7)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="From Fund Name"
                name="fromFundId"
                value={getFundName(ftDetails.fromFundId)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="To Fund Name"
                name="toFundId"
                value={getFundName(ftDetails.toFundId)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Transfer Amount"
                name="transferAmount"
                value={Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(ftDetails.transferAmount)}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="From Number of Units"
                name="fromNumOfUnits"
                value={Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 4,
                }).format(ftDetails.fromNumOfUnits)}
              />
            </GridDX>

            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="To Number of Units"
                name="toNumOfUnits"
                value={Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 4,
                }).format(ftDetails.fromNumOfUnits)}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="From Nav Applied"
                name="fromNavApplied"
                value={ftDetails.fromNavApplied}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="To Nav Applied"
                name="toNavApplied"
                value={ftDetails.fromNavApplied}
              />
            </GridDX>
            <GridDX item xs={12}>
              <DiscTextFieldDX
                label="Status"
                name="requestStatus"
                value={getReqeustStatus(ftDetails.requestStatus)?.value}
              />
            </GridDX>
          </GridDX>
        </GridDX>
      )}
    </LocalizationProvider>
  );
};

export default FundsTransferDetails;
